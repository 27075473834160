export default {
  claim: {
    source: 'claim',
    type: Object
  },
  condition: {
    source: 'condition',
    type: Object
  },
  isRecurring: {
    default: false,
    source: 'is_recurring',
    type: Boolean
  },
  onClaim: {
    default: false,
    source: 'on_claim',
    type: Boolean
  },
  recurrence: {
    source: 'recurrence',
    type: Object
  },
  templateId: {
    source: 'template_id',
    type: String
  },
  validityPeriod: {
    format: value => value != null ? Number(value) : null,
    source: 'validity_period'
  }
}