export default {
  fqn: 'ifm.catalog',

  endpoints: {
    entity: {
      get: '/catalog/$id?locale=$locale&service=$service'
    },

    transport: 'cp',
  },
}
