import { basil } from '@spices/basil'

export default class Order {
	constructor(value){
		Object.keys(value).forEach(k => {
			this[k] = value[k]
		})

		if (this.service === 'base'){
			this.service = 'pickup'
		}
		
		if(this.order_items) {
			this.order_items.forEach(item => {
				if(item && item.options) {
					item.options.forEach(opt => {
						Object.defineProperty(opt, 'toString', { 
							get() {
								let ret = opt.locale.split(',').reduce((acc, val) => {
									let index = acc.findIndex((el) => el.val === val)

										if (index > -1) {
												acc[index].qty++
										} else {
												acc.push({
														val,
														qty: 1
												})
										}

										return acc
								}, []).reduce((acc, val) => {
										acc.push(val.val + (val.qty > 1 ? ' x' + val.qty : ''))

										return acc
								}, []).join(', ')
								return ret
							}
						})
					})
				}
			})
		}
	}

	get isCancellable() {
		return this.payment_status === '0' && this.order_status === 6
	}

	/**
	 * Whether or not the payment is failed
	 * 
	 * @property {Boolean} isFailed
	 */
	get isFailed(){
		return this.payment_status !== '1'
	}

	///////////////////////////////////////////////////////////

	toGtagAPI({ catalog, isCheckout = true, shop }) {
		let currency = basil.get(this, 'currency.code', null)

		let payload = {
			currency,
			items: []
		}

		let items = []

		let orderItems = basil.get(this, 'order_items', [])
		
		orderItems.map(item => {
			let product = null
			// let categ = catalog.categories.find(c => !basil.isNil(c.products.find(p => p.id === item.product_id)))

			if(basil.get(item, 'type', 'default') === 'default') {
				product = {
					affiliation: basil.get(shop, 'name', null),
					item_id: basil.get(item, 'sku', null),
					item_name: basil.get(item, 'locale_title', null),
					// item_category: basil.get(categ, 'name', null),
					// coupon: null,
					// discount: null,
					
					item_variant: '',
					currency,
					price: basil.get(item, 'base_total_price', 0),
					quantity: basil.get(item, 'quantity', 0),
				}
				items.push(product)

				let orderOptions = basil.get(item, 'options', [])
				let options = []
				
				orderOptions.forEach(opt => {
					let values = opt.locale.split(',').reduce((acc, val, i) => {
						let codes = opt.value.split(',')

						let index = acc.findIndex((el) => el.val === val)

						if (index > -1) {
								acc[index].qty++
						} else {
								acc.push({
									code: codes[i],
									val,
									qty: 1
								})
						}

						return acc
					}, [])
					
					if(values.length > 0) {
						values.forEach((v, vIndex) => {
							options.push({
								affiliation: basil.get(shop, 'name', null),
								currency,
								
								item_id: basil.get(v, 'code', null),
								item_name: basil.get(v, 'val', null),
								// item_list_name: basil.get(opt, 'option_title', null),
								// item_list_id: basil.get(opt, 'code', null),
								index: vIndex,
								price: basil.get(opt, 'price_variation', 0) * v.qty,
								
								item_category: basil.get(opt, 'option_title', null),
								item_variant: '',
								item_brand: null,
								quantity: v.qty,
							})
						})
					}
				})

				items.push(...options)
			}
		})

    let ret = {
			currency,
			items,
			value: basil.get(this, 'base_price_total', null)
		}

		if(!isCheckout) {
			ret.value = basil.get(this, 'price_total', null)
			ret.affiliation = basil.get(shop, 'name', null)
			ret.transaction_id = basil.get(this, 'pickup_code', null)
			ret.shipping = basil.get(this, 'delivery_costs', 0)
			ret.tax = basil.get(this, 'vat_total', 0)
		}

		return ret
	}
}
