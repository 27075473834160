export default {
  fqn: 'ifm.nft',
  endpoints: {
    collection: {
      get: '/embed/$embedId/nfts'
    },

    entity: {
      get: '/nft/$id?with-tokens=$withTokens'
    },

    transport: 'cp'
  },
}
