import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
// import mitt from 'mitt'

import { Logger, Level } from '@spices/cayenne'
import { Anise, Field } from '@spices/anise'

import initApplication from './plugins/app'
import initBody from './plugins/body'
import initCP from './plugins/cp'
import initCore from './plugins/core'
import initInjekt from './plugins/injekt'
import initModules from './plugins/modules'
import initObserver from './plugins/observer'
import badge from './plugins/badge'

import Error from '../helpers/error'
import { default as Config, isDevelopment, isStaging, isProduction } from '../config'

import { CoreController } from '../core'
import eventbus from './eventbus'
import router from './router'
import SaylStore from './stores'
import cp from './data/api/transports/cp'
import http from './data/api/transports/http'
import states from './states'

import i18n from './data/i18n/i18n'

const onError = (event) => {
  console.group('base:error')
  console.error(event)
  console.groupEnd('base:error')
}

//
// Error Management
//
window.addEventListener('error', onError)

let bootstrap = {}
bootstrap.install = async (Vue) => {
  //
  // Badge
  //
  badge()

  //
  // Bugsnag
  //
  if(!isDevelopment()) {
    Bugsnag.start({
      apiKey: '0eaaf5cf04f158ba0db36a48ca5f664d',
      plugins: [
        new BugsnagPluginVue(),
      ],
      appType: 'client',
      appVersion: Config.version.client,
      releaseStage: Config.env
    })
    Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
  }

  //
  // Args
  //
  let args = Anise.detect([
    // Cart
    new Field({ name: 'cart' }),

    // Debug
    new Field({
      name: 'debug',
      fallback: 'off',
      persistent: true,
      search: /debug=(all|debug|info|warn|error)/
    }),

    // Lang
    new Field({
      name: 'lang',
      search: /lang=([\w]+)/,
    }),

    // nocache
    new Field({
      name: 'nocache',
      search: /nocache=([\w]+)/
    })
  ])
  
  //
  // Logger
  //
  let logger = Logger.get()
  logger.level = Level.getFromName(args.debug.toUpperCase())
  logger.debug('args', args)
  Object.defineProperty(window, '$console', {
    get: () => logger
  })


  logger.info('Stage: ')
  isDevelopment() && logger.info('Development')
  isStaging() && logger.info('Staging')
  isProduction() && logger.info('Production')

  //
  // Transports
  //
  let transports = { cp, http }
  
  // const emitter = mitt()

  // if(window && !window.SaylConn3ctEmitter) {
  //   window.SaylConn3ctEmitter = emitter
  // }

  //
  // Capacities
  //
  let capabilities = {
    args,
    eventbus,
    // emitter,
    logger,
    router,
    states,
    store: SaylStore,
    transports,
    Vue
  }

  capabilities.i18n = new i18n({ locale: args.lang, transports, Vue })
  capabilities.core = new CoreController(capabilities)

  //
  // Boostrap
  //
  try {
    await initApplication(capabilities)
    await initCP(capabilities)
    await initCore(capabilities)
    await initModules(capabilities)
    await initInjekt(capabilities)
    await initBody(capabilities)
    await initObserver(capabilities)

    SaylStore.bootstrap().status = states.APPLICATION_COMPLETE
    Config.state = states.APPLICATION_COMPLETE

    let func = () => {
      try {
        sayl.injekt.sp.apply('trackPageView')
      } catch(e) {
        setTimeout(() => func(), 250)
      }
    }

    func()
  } catch(error) {
    let e = error

    if(!!error && !error.hasOwnProperty('slug')) {
      e = Error.BOOTSTRAP_ERROR
      e.error = Object.assign({}, error)

      Bugsnag.notify(error)
    }

    logger.error(error)
    SaylStore.bootstrap().errors = [e]
  }
}

export default bootstrap
