export default {
  allergens: {
    source: 'allergens',
    type: Array
  },
  description: {
    source: 'descr',
    type: String
  },
  figures: {
    source: 'figures',
    type: Array
  },
  hidden: {
    source: 'hidden',
    type: Boolean
  },
  id: {
    source: 'id',
    type: String
  },
  imageType: {
    source: 'image_type',
    type: String
  },
  name: {
    source: 'title',
    type: String
  },
  options: {
    source: 'options',
    type: Array
  },
  parent: {
    source: 'parent',
    type: String
  },
  position: {
    source: 'position',
    type: Number
  },
  slug: {
    source: 'slug',
    type: String
  },
  type: {
    source: 'type',
    type: String
  }
}