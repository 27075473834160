<template>
  <footer
    class="n-footer"
    v-if="enabled">
    <div class="n-footer__container n-footer__license"> 
      <!-- Copyright -->
      <div class="n-footer__copyright" v-html="$t('conn3ct-store.copyright', { year })"></div>

      <!-- Legal -->
      <ul class="n-footer__legal">
        <li
          class="n-footer__item"
          :key="item.label"
          v-for="item in legal">
          <a 
            :href="item.href"
            class="n-footer__link"
            target="_blank"
            rel="nofollow"
            v-if="item.href"
          >{{ item.label }}</a>

          <div 
            class="n-footer__link -action"
            rel="nofollow"
            @click="item.event"
            v-if="item.event"
          >{{ item.label }}</div>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import SaylStore from '@/bootstrap/stores'

export default {
  name: 'SaylFrontFooter',

  inject: [
    '$user',
    '$embed',
    '$localStorage',
  ],

  data() {
    return {
      bootstrap: SaylStore.bootstrap(),
      key: 1,
    }
  },

  computed: {
    cookiesDisabled() {
      return this.$basil.get(this.bootstrap, 'cookies.disabled', false)
    },

    enabled() {
      let meta = this.$route.meta
      return !meta.hasOwnProperty('footer') || (meta.hasOwnProperty('footer') && meta.footer === true)
    },

    lang() {
      return !this.$basil.isNil(this.user.lang) ? this.user.lang : this.$basil.i18n.locale.lang
    },

    legal() {
      let terms = this.$basil.get(this.$embed, 'embed.model.generalConditions', null)
      let privacy = this.$basil.get(this.$embed, 'embed.model.privacyPolicy', null)

      return [
        { 
          label: this.$t('conn3ct-store.burger_legal_term_and_conditions'), 
          // href: terms ? terms : this.$t('conn3ct-store.burger_legal_term_and_conditions_link', { lang: this.lang }),
          event: this.onShowTerms 
        },
        { 
          label: this.$t('conn3ct-store.burger_legal_privacy_policy'), 
          href: privacy ? privacy : this.$t('conn3ct-store.burger_legal_privacy_policy_link', { lang: this.lang }) 
        },
        !this.cookiesDisabled ? {
          label: this.$t('user-portal.burger_legal_cookies_settings'),
          event: () => this.$bus.$emit('cookies-settings')
        } : null
      ].filter((l) => l != null)
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    year() {
      return new Date().getFullYear()
    }
  },

  methods: {
    reset() {
      try {
        let e = this.$el
        let b = !!e ? e.getBoundingClientRect() : null
        let h = (!!b ? b.height : 0 | 0) + (1 * 16)

        document.getElementsByTagName('main')[0].style.marginBottom = `${h}px`
      } catch(error) {}
    },

    onResize() {
      this.reset()
    },

    onShowTerms() {
      this.$bus.$emit('terms')
    }
  },

  mounted() {
    window.addEventListener('resize', this.$basil.debounce(this.onResize.bind(this), 500))
    this.reset()
  },

  destroy() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>
