import SaylStore from '@/bootstrap/stores'

export default {
  name: 'CookiesMixin',

  inject: ['$embed'],

  data() {
    return {
      bootstrap: SaylStore.bootstrap(),
      key: 1,
    }
  },

  computed: {
    disabled() {
      return this.bootstrap.cookies.disabled
    },

    expired() {
      return parseInt(this.$basil.get(this.bootstrap, '.data.gdpr.cookies_expiration', 60 * 60 * 24 * 30), 10) * 1000
    },

    name() {
      return `cookies_${this.$basil.get(this.$embed, 'embed.model.id')}`
    },
  
    options() {
      let ret = [
        {
          title: this.$t('conn3ct-store.cookies_options_technical_title'),
          description: this.$t('conn3ct-store.cookies_options_technical_description'),
          required: true,
          key: 'technical'
        },
        {
          title: this.$t('conn3ct-store.cookies_options_analytics_title'),
          description: this.$t('conn3ct-store.cookies_options_analytics_description'),
          key: 'analytics'
        },

        // Add other key in case it is needed
      ].filter(opt => opt != null)

      return ret
    },

    show() {
      return [
        !this.valid,
        !this.disabled,
        // Add here the condition for the customer choice
      ].filter(s => s === false).length === 0
    },

    valid() {
      return this.bootstrap.cookies.valid
    }
  },

  methods: {
    cook(values) {
      Array.from(document.querySelectorAll('script[type="text/plain"]')).forEach(el => {
        values.subjects
          .filter((subject) => subject.value)
          .forEach((subject) => {
            if(el.className.includes(`-${subject.key}`)) {
              let script = el.cloneNode(true)
              script.setAttribute('type', 'text/javascript')
              el.parentNode.replaceChild(script, el)
            }
          })
      })
      this.bootstrap.cookies.valid = true
      this.key++

      if(this.bootstrap.cookies.callback != null) {
        this.bootstrap.cookies.callback()
        this.bootstrap.cookies.callback = null
      }
    },

    init(force = false) {
      let storage = localStorage.getItem(this.name)
      
      if(storage != null) {
        try {
          storage = JSON.parse(storage)
          if(storage.ts + this.expired <= Date.now()) {
            localStorage.removeItem(this.name)
            storage = null
          }
          
        } catch(e) {
          storage = null
        }
      }

      if(storage != null && !force) {
        this.cook(storage)
        return
      } 

      this.options.forEach(opt => {
        let prec = null

        if(storage && storage.subjects) {
          prec = storage.subjects.find(s => s.key === opt.key)
        } 

        if(opt.required) {
          this.value[opt.key] = prec != null ? prec.value : (opt.default != null ? opt.default : true)
        } else {
          this.value[opt.key] = prec != null ? prec.value : (opt.default != null ? opt.default : false)
        }
      })

      this.bootstrap.cookies.valid = false

      if(force === true) {
        this.bootstrap.cookies.callback = () => {
          window.location.reload()
        }
      }

      this.key++
    },

    onAcceptAll() {
      let ret = {
        subjects: this.options.map((opt) => {
          return { key: opt.key, value: true }
        }),
        ts: Date.now()
      }

      localStorage.setItem(this.name, JSON.stringify(ret))

      this.cook(ret)
      this.back && this.back()
    },

    onAcceptNecessary() {
      let ret = {
        subjects: Object.keys(this.value).map((key) => {
          let opt = this.options.find(o => o.key === key)
          return { key: key, value: opt.required || false }
        }),
        ts: Date.now()
      }

      localStorage.setItem(this.name, JSON.stringify(ret))

      this.cook(ret)
      this.back && this.back()
    },

    onAcceptSelection() {
      let ret = {
        subjects: Object.keys(this.value).map((key) => {
          return { key: key, value: this.value[key] }
        }),
        ts: Date.now()
      }

      localStorage.setItem(this.name, JSON.stringify(ret))

      this.cook(ret)
      this.back && this.back()
    },
  },
}