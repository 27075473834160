import ProductNft from '../model'
import ProductNftCatalogManifest from './manifest'

/** 
 * @class
 * @author Valentin Gregoire <valentin@infinity-mobile.io>
 */
export default class ProductNftCatalog extends ProductNft {
  /**
   * @constructor
   */
  constructor(data = null, manifest = null) {
    super(data, manifest == null ? ProductNftCatalogManifest : manifest)

    this._category = null
  }

  /////////////////////////////////////////
  ///           GETTERS
  /**
   * @property {Object}
   */
  get category() {
    return this._category
  }
  
  /**
   * @param {Object} value
   */
  set category(value) {
    this._category = value
    this._categoryId = value.id
  }
}
