import { CurryModel } from '@spices/curry'
import manifest from './manifest'

import { basil } from '@spices/basil'
import Config from '@/config.js'

/** 
 * @class
 * @author Valentin Gregoire <valentin@infinity-mobile.io>
 */
export default class Embed extends CurryModel {
  /**
   * @constructor
   */
  constructor(data = null) {
    super({ data, manifest })
  }

  get socials() {
    let baseLink = basil.get(Config, 'icons', 'https://cdn-apps.sayl.cloud/user/statics/images/icons/social/')

    return [
      this.facebookPage ? {
        type: 'facebook',
        href: `https://facebook.com/${this.facebookPage}`,
        icon: `${baseLink}facebook.svg`
      } : null,

      this.instagramPage ? {
        type: 'instagram',
        href: `https://instagram.com/${this.instagramPage}`,
        icon: `${baseLink}instagram.svg`
      } : null,
      
      this.messengerPage ? {
        type: 'messenger',
        href: `https://messenger.com/${this.messengerPage}`,
        icon: `${baseLink}messenger.svg`
      } : null,

      this.pinterestPage ? {
        type: 'pinterest',
        href: `https://pinterest.com/${this.pinterestPage}`,
        icon: `${baseLink}pinterest.svg`
      } : null,
      
      this.twitterPage ? {
        type: 'twitter',
        href: `https://twitter.com/${this.twitterPage}`,
        icon: `${baseLink}twitter.svg`
      } : null,
      
      this.youtubePage ? {
        type: 'youtube',
        href: `https://youtube.com/@${this.youtubePage}`,
        icon: `${baseLink}youtube.svg`
      } : null,
    ].filter(s => s != null)
  }
}
