import CatalogController from './catalogs/controller'
import ProductController from './products/controller'
import Error from '../error'

import { useCatalogStore } from './store'

/**
 * @class
 */
export default class CatalogMasterController {
  /**
   * @constructor
   * 
   * @param {Object} options
   * @param {Object} options.logger - Logger we use in all the app. In the view $console and here logger. See @spices/cayenne
   * @param {Object} options.transports - Object containing cp and HTTP. See @/bootstrap/data/api/transports
   */
  constructor({ logger, transports }) {
    this._logger = logger

    this._store = useCatalogStore
    
    this._catalog = new CatalogController({ logger, store: this._store, transports })
    this._product = new ProductController({ logger, store: this._store, transports })
  }

  /////////////////////////////////////////
  ///           GETTERS
  /**
   * @property {CatalogController}
   * @readonly
   */
  get catalog() {
    return this._catalog
  }

  /**
   * @property {Boolean}
   * @readonly
   */
  get loading() {
    return this._store().loading
  }

  /**
   * @property {ProductController}
   * @readonly
   */
  get product() {
    return this._product
  }
  
  /////////////////////////////////////////
  ///           INIT
  /**
   * Initialize the controller
   * 
   * @async
   * @param {Object} options 
   * @param {Object} options.catalog 
   * @param {String} options.id 
   * @param {Locale} options.locale 
   * @param {String} options.service 
   * 
   * @return {Array<Array<Catalog>, Array<NftCatalog>>}
   */
  async init({ catalog, id, locale, service = 'virtual', refresh = false }){
    try {
      // 1. fetch the data if they are not provided via `catalog`
      // 2. model the catalog and the products
      // 3. link them together

      this._logger.info('catalog.init')
      this._logger.debug('catalog', catalog ? catalog : id)

      this._store().loading = false

      if(!catalog && !id) {
        throw Error.SERVICE_NOCATALOG
      }

      if(this.catalog.catalog != null && !refresh) {
        return this.catalog.catalog
      }

      let data = catalog == null ? await this._catalog.find({ id, locale, service }) : catalog
      let ret = await Promise.all([
        await this.catalog.init({ catalog: data.catalog }),
        await this.product.init({ products: data.products, service })
      ])

      this.catalog.link(ret, refresh)
      this.catalog.filter(ret)

      return ret
    } catch(e) {
      throw e
    } finally {
      this._store().loading = false
    }
  }
}
