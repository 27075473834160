export default {
  assetContract: { source: 'asset_contract', type: String },
  attributes: { source: 'attributes', type: Array },
  currentOwner: { source: 'current_owner', type: String },
  description: { source: 'description', type: String },
  id: { source: 'id', type: String },
  imageUrl: { source: 'image_url', type: String },
  mintDate : { source: 'mint_date', format: (value) => value == null ? null : Date.parse(value) },
  name: { source: 'name', type: String },
  nftId: { source: 'nft_id', type: String },
  optimizedImageUrl: { source: 'optimized_image_url', type: String },
  purchasable: { source: 'purchasable', type: Boolean },
  purchasing: { source: 'purchasing', type: Boolean },
  tokenId: { source: 'token_id', type: Number }
}