export default {
  description: {
    source: 'description',
    type: String
  },
  metadata: {
    source: 'metadata',
    type: Array
  },
  picture: {
    source: 'picture',
    type: String
  },
  slug: {
    source: 'slug',
    type: String
  },
  title: {
    source: 'title',
    type: String
  }
}