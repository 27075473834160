import { basil } from '@spices/basil'
import { version as app } from '../package.json'

function getEnvValue(name, defaultValue){
  let ret = defaultValue // default value for the mutualised

  const isArgument = (e) => typeof e === 'object' && e !== null && e.toString() === '[object Arguments]'
  let dl = basil.get(window, 'dataLayer', [])
  let v = dl.find(e => {
    let ret = false

    if (isArgument(e) && (e.hasOwnProperty('length') && e.length == 2)) {
      let [n, value] = e
      ret = name === n
    }

    return ret
  })
  ret = basil.get(v, '[1]', ret)

  return ret
}

const config = {
  baseURL: [document.location.origin, process.env.VUE_APP_NAME || '/'].join('/'),
  cdn: process.env.VUE_APP_CDN || '/',
  embedId: getEnvValue('conn3ct.embedId', null),
  env: process.env.VUE_APP_ENV || 'production',
  injekt: {
    debug: process.env.VUE_APP_INJEKT_DEBUG || false,
    env: process.env.VUE_APP_INJEKT_ENV || 'production'
  },
  libs: process.env.VUE_APP_LIBS || '/',
  publicPath: getEnvValue('conn3ct.publicPath', '/conn3ct-store/'),
  state: 0,
  hederaExplorerUrl: process.env.VUE_APP_HEDERA_EXPLORER || 'https://testnet.dragonglass.me/hedera/',
  transports: {
    cp: {
      api: process.env.VUE_APP_API,
      oauth: process.env.VUE_APP_OAUTH,
    },

    module: process.env.VUE_APP_MODULE,
    timeout: process.env.VUE_APP_TIMEOUT || 5000
  },
  version: {
    client: app
  },
  terms: 'https://various-storage.s3.eu-central-1.amazonaws.com/sayl-conn3ct-store/all.html',
  wallet: {
    url: process.env.VUE_APP_WALLET_URL || 'https://dev.wallet.infinity-commerce.io/login'
  }
}

const isDevelopment = () => config.env === 'development'
const isStaging = () => config.env === 'staging'
const isProduction = () => config.env === 'production'

if(isStaging()) {
  config.cdn = 'https://cdn-apps.sayl.cloud/staging/conn3ct-store/'
}
if(isDevelopment()) {
  config.cdn = '/'
}

export default config
export {
  isDevelopment,
  isProduction,
  isStaging,
}
