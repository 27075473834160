import Category from './categories/model'

export default {
  categories: {
    format: values => {
      return values && values.length > 0 ? values.map(v => new Category(v)) : [];
    },
    source: 'categ_prod',
    type: Array
  },
  customer_id: {
    source: 'customer_id',
    type: String
  },
  descr: {
    source: 'descr',
    type: String
  },
  id: {
    source: 'id',
    type: String
  },
  images: {
    source: 'images',
    type: Array
  },
  title: {
    source: 'title',
    type: String
  }
}