<template>
  <popins-modal
    :closable="closable"
    :klass="classes"
    :visible="visible"
    :position="position"
    :size="size"
    v-on="$listeners"
    @close="close">
    <template #header>
      <slot name="header"></slot>
    </template>
    
    <div class="layout-modal__body">
      <slot v-bind="bodyAttrs"></slot>
    </div>

    <template v-slot:footer>
      <slot 
        name="footer" 
        v-bind="bodyAttrs"></slot>
    </template>
  </popins-modal>
</template>


<script>
export default {
  name: 'LayoutModal',

  props: {
    closable: {
      default: true,
      type: Boolean
    },
    position: {
      default: 'top-center',
      type: String
    },
    searchable: {
      default: false,
      type: Boolean
    },
    size: {
      default: null,
      type: String
    },
    visible: {
      default: false,
      type: Boolean
    }
  },

  computed: {
    bodyAttrs() {
      return {
        close: this.close
      }
    },

    classes() {
      let ret = {
        'layout-modal': true
      }

      return {
        ...ret,
        ...this.$attrs.classes
      }
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },

    onSearch(value) {
      this.$emit('search', value)
    }
  },
};
</script>
