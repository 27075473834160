export default {
  fqn: 'ifm.shop',
  endpoints: {
    entity: {
      get: '/shop/$id?locale=$locale'
    },
    
    countries: {
      get: '/countries/$shop_id?locale=$locale'
    },

    transport: 'cp'
  }
}
