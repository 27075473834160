import { defineStore } from 'pinia'

/**
 * Application status
 */
import states from '@/bootstrap/states'
import { basil } from '@spices/basil'


export const useBootstrapStore = defineStore('bootstrap', {
  state: () => {
    let _theme = localStorage.getItem('theme') || 'light'
    if(_theme === 'dark') {
      document.body.classList.add('-dark')
    } 

    return {
      fqn: null,
      model: null,

      // Loading
      errors: [],
      status: 0,
      states: states,

      // Navigations
      navigation: null,

      // i18n
      i18n: null,
      locale: null,
      locales: null,

      // Config (cp bootstrap)
      data: null,
      args: null,

      routes: [],

      themeable: true,
      theme: _theme,

      // Cookie
      cookies: {
        valid: true,
        callback: null,
        disabled: false,
      },
    }
  },

  getters: {
    settings: (state) => {
      let d = {
        custom_tc_url: null,
        has_comment: { all: true },
        has_email: { all: true },
        has_fl_selection: true,
        has_newsletter: { all: true },
        has_phone: { all: true },
        has_search : false,
        is_catalog_readonly: false,
        is_category_pictures_enabled: true,
        is_email_required: { all: true },
        is_newsletter_opt_out: { all: true },
        is_phone_required: { all: true },
        is_product_pictures_enabled: true,
        is_recommendations_enabled: false,
        is_terms_opt_out: { all: true },
        is_tracing_form_enabled: false,
        is_layout_product_inverted: false,
        is_voucher_enabled: true,
        pin : '1234',
        template : 'spa',
      }

      let shop = basil.get(state, 'data.shop.id', null)
      let settings = basil.get(state, 'data.extensions.conn3ct-store', [])
      let ret = { allergens: [] }

      if(settings && shop !=  null) {
        ret = settings.find(s => s.shop_id === shop)
      }

      return Object.assign(d, ret)
    },
    
    isLightTheme: (state) => {
      return state.theme === 'light'
    }
  },

  actions: {
    getConfig() {
      return Promise.resolve(this.data)
    },

    setError(value) {
      this.errors.push(value)
    },

    setI18n(value) {
      this.i18n = value
      this.locale = value.locale
      this.locales = value.locales
    },

    setLocale(value) {
      this.i18n.setLocale(value.locale)
    },

    setNavigation(value) {
      this.navigation = value
    },

    setStatus(value) {
      this.status = value
    },

    switchTheme() {
      if(this.themeable === false) {
        document.body.classList.remove('-dark')
        this.theme = 'custom'
      }

      if(this.theme !== 'custom') {
        this.theme = this.theme === 'dark' ? 'light' : 'dark'
  
        localStorage.setItem('theme', this.theme)
        if(this.theme === 'dark') {
          document.body.classList.add('-dark')
        } else {
          document.body.classList.remove('-dark')
        }
      }

      if(window.bodyClass) {
        window.bodyClass.bodyClass = document.body.className
      }
    },
  },
})
