<template>
  <section 
    class="modal-cookies"
    v-if="show && !showSettings">
    <div class="container">
      <h4 class="modal-cookies__title">{{ $t('conn3ct-store.cookies_default_title') }}</h4>
      
      <p class="modal-cookies__description">{{ $t('conn3ct-store.cookies_default_description') }}</p>

      <div class="modal-cookies__actions --between">
        <actions-button
          class="modal-cookies__action"
          :appearance="$pepper.Appearance.DEFAULT"
          :size="$pepper.Size.S"
          @click="onSettingsShow"
        >{{ $t('conn3ct-store.cookies_personalize_action') }}</actions-button>

        <div class="modal-cookies__actions">
          <actions-button
            class="modal-cookies__action"
            :appearance="$pepper.Appearance.default"
            :size="$pepper.Size.S"
            @click="onAcceptNecessary"
          >{{ $t('conn3ct-store.cookies_only_necessary_action') }}</actions-button>

          <actions-button
            class="modal-cookies__action"
            :appearance="$pepper.Appearance.PRIMARY"
            :size="$pepper.Size.S"
            @click="onAcceptAll"
          >{{ $t('conn3ct-store.cookies_accept_all_action') }}</actions-button>
        </div>
      </div>
    </div>
  </section>

  <modal-cookies-settings
    :value="value"
    :visible="showSettings"
    @close="onSettingsClose"
    v-else-if="showSettings && show"
  />
</template>

<script>
import ModalCookiesSettings from './cookies-settings.vue'

import CookiesMixin from '@/helpers/cookies'

export default {
  name: 'ModalCookie',

  components: {
    ModalCookiesSettings,
  },

  inject: [
    '$embed',
  ],

  mixins: [
    CookiesMixin
  ],

  data() {
    return {
      showSettings: false,
      value: {},
    }
  },

  methods: {
    onSettingsClose() {
      this.showSettings = false
    },

    onSettingsShow() {
      this.showSettings = true
    }
  },

  mounted() {
    if(!this.disabled) {
      this.init()

      this.$bus.$on('cookies-settings', () => {
        this.bootstrap.burger = false
        this.init(true)
      })
    }
  },

  beforeDestroy() {
    this.$bus.$off('cookies-settings')
  },
}
</script>