import { default as Config, isDevelopment } from '@/config'
import SaylStore from '../stores'

export default async ({ args, core, logger, store, transports }) => {
  try {
    logger.group('bootstrap.front.core')

    transports.getByName = (name) => {
      return transports[name]
    }

    let bootstrap = await store.bootstrap().getConfig()

    await core.init({ args, bootstrap, store })
    document.title = core.embed.shop.model.name

    if(isDevelopment()) {
      let style = document.createElement('style')
      style.innerHTML = core.embed.embed.model.customCss
      document.head.append(style)
    }

    if( core.embed.embed && 
        core.embed.embed.model && 
        core.embed.embed.model.customCss != null && 
        core.embed.embed.model.customCss != ''
    ) {
      document.body.classList.remove('-dark')
      SaylStore.bootstrap().themeable = false
      SaylStore.bootstrap().switchTheme()
    }

    return
  } catch(e) {
    throw e
  } finally {
    logger.groupEnd('bootstrap.front.core')
  }
}
