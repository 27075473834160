import { basil } from '@spices/basil'
import Error from '../error'

import EmbedController from './embeds/controller'
import ShopController from './shops/controller'

import { useEmbedStore } from './store'

/**
 * @class
 */
export default class EmbedMasterController {
  /**
   * @constructor
   * 
   * @param {Object} options
   * @param {Object} options.logger - Logger we use in all the app. In the view $console and here logger. See @spices/cayenne
   * @param {Object} options.transports - Object containing cp and HTTP. See @/bootstrap/data/api/transports
   */
  constructor({ logger, transports }) {
    this._logger = logger
    this._store = useEmbedStore

    this._embed = new EmbedController({ logger, store: this._store, transports })
    this._shop = new ShopController({ logger, store: this._store, transports })
  }

  /////////////////////////////////////////
  ///           GETTERS
  /**
   * @property {EmbedController}
   * @readonly
   */
  get embed() { 
    return this._embed
  }
  
  /**
   * @property {ShopController}
   * @readonly
   */
  get shop() {
    return this._shop
  }

  /////////////////////////////////////////
  ///           INIT
  /**
   * Initialise the controller to handle Shop and Embed controllers
   * 
   * @async 
   * @param {Object} options 
   * @param {Object} options.embed 
   * @param {String} options.locale
   * @param {String} options.service 
   * @param {Object} options.shop 
   */
  async init({ embed, locale, service, shop }) {
    try {
      this._logger.group('embed.init')
      this._store().loading = true

      // 1. Generate the models
      // 2. Exclude the unwanted services
      // 3. Start the services
      // 4. Link the data
      await Promise.all([
        this.embed.init({ embed, locale }),
        this.shop.init({ shop, locale }),
      ])

      await this.shop.filterServices({ excluded: this.embed.model.excludedServices })
      
      // Check if any service is active
      if(this.shop.model.services.length === 0) {
        throw Error.SHOP_NOACTIVESERVICE
      }

      // Check if the requested service is active
      let s = this.shop.model.services.find(s => s.name === service)
      if(basil.isNil(s) && !basil.isNil(s)) {
        throw Error.SERVICE_NOTACTIVE
      }
    } catch(e) {
      throw e
    } finally {
      this._logger.debug('-embed', this.embed.model)
      this._logger.debug('-shop', this.shop.model)
      this._logger.groupEnd('embed.init')

      this._store().loading = false
    }
  }

  /////////////////////////////////////////
  ///           METHODS
  /**
   * Reset the shop and the embed by fetching them again from server
   * 
   * @async 
   * @param {Object} options
   * @param {Object} options.locale
   */
  async reset({ locale }) {
    try {
      await Promise.all([
        this.embed.init({ id: this.embed.model.id, locale }),
        this.shop.init({ id: this.embed.model.shopId, locale }),
      ])
      await this.shop.filterServices({ excluded: this.embed.model.excludedServices })
    } catch(e) {
      throw e
    }
  }
}
