export default () => {
  let ret = []

  ret.push({
    path: '/',
    component: () => import('./catalog'),
    name: 'sayl-front-catalog.catalog',
    meta: {
      bodyClass: 'view-catalog-shop catalog'
    }
  })

  ret.push({
    path: '/:product',
    name: 'sayl-front-catalog.product',
    component: () => import('./product'),
    meta: {
      bodyClass: 'product'
    }
  })

  ret.push({
    path: '/:product/buy',
    name: 'sayl-front-catalog.checkout',
    component: () => import('./checkout'),
    meta: {
      bodyClass: 'checkout'
    },
  })

  ret.push({
    path: '/checkout/confirm/:id',
    name: 'sayl-front-catalog.confirm',
    component: () => import('./confirm'),
    meta: {
      scrollTop: true,
      header: true
    }
  })

  return ret
}
