import { basil } from '@spices/basil'
import { CurryApi } from '@spices/curry'
import config from './config'

import { useNftStore } from './store'

import NftCatalog from './models/nfts/catalogs/model'
import ProductNftToken from './models/nfts/tokens/model'

/**
 * @class
 */
export default class NftController {
  /**
   * @constructor
   * 
   * @param {Object} options
   * @param {LocalStorageController} options.localStorage - LocalStorage manager scoped. See @/core/local-storage/controller.js
   * @param {Object} options.logger - Logger we use in all the app. In the view $console and here logger. See @spices/cayenne
   * @param {Object} options.transports - Object containing cp and HTTP. See @/bootstrap/data/api/transports
   */
  constructor({ localStorage, logger, transports }) {
    this._api = new CurryApi({ config: config, transports })
    this._localStorage = localStorage
    this._logger = logger
    this._store = useNftStore
    this._transports = transports
  }

  /////////////////////////////////////////
  ///           GETTERS
  /**
   * @property {Array} nfts
   * @return {Array}
   */
  get nfts() {
    return this._store().nfts || []
  }

  /////////////////////////////////////////
  ///           INIT
  /**
   * Initialize the list of nfts
   * 
   * @async
   * @param {Object} options 
   * @param {String} options.embedId - If options.nfts is null, sending this value will reload the nfts
   * @param {Array} options.nfts - Array of NFTs, default null
   */
  async init({ embedId = null, nfts = null }) {
    try {
      this._logger.info('catalog.nfts.init')
      
      if(nfts) {
        this._store().nfts = nfts.map((nft) => new NftCatalog(nft))
        return this.nfts
      }

      if(embedId) {
        await this.find({ embedId })
      }
    } catch(e) {
      throw e
    }
  }

  /////////////////////////////////////////
  ///           METHODS
  /**
   * Fetch the NFTs from the server based on an embed id
   * 
   * @async
   * @param {Object} options
   * @param {String} options.embedId
   * 
   * @returns {Array<NftCatalog>}
   */
  async find({ embedId }) {
    try {
      this._logger.group('catalog.nft.fetch')
      this._logger.info('embedId', embedId)

      let { data } = await this._api.get({ type: 'collection', payload: { embedId } })
      let transport = this._transports.getByName('http')
      
      data.forEach(async (d) => {
        try {
          let { headers } = await transport.head(basil.get(d, 'nft.asset_url'))
          let type = basil.get(headers, 'content-type', 'image/')
          d.image_type = type ? type : 'image/'
        } catch(e) {
          d.image_type = 'image/'
        }
      })
      await this.init({ nfts: data })

      return this.nfts
    } catch(e) {
      throw e
    } finally {
      this._logger.groupEnd('catalog.nft.fetch')
    }
  }

  /**
   * Find a product matching the given id in the existing list of NFTs
   * 
   * @param {String} id 
   * 
   * @returns {NftCatalog}
   */
  findById(id) {
    this._logger.info('catalog.nft.getNftById')
    this._logger.debug('id', id)
    
    return this.nfts.find(n => n.id === id)
  }

  /**
   * Fetch an NFT from the server
   * 
   * The response from the server if interprated as an NFT Catalog and
   * an Array of triggers
   * 
   * @async
   * @param {String} id 
   * 
   * @returns {Object<NftCatalog, Array, Array>}
   */
  async getNft(id, withTokens = false) {
    this._logger.info('catalog.nft.fetchNft');
    this._logger.debug('id', id);

    try {
      let payload = { id }
      if (withTokens) {
        payload.withTokens = 1
      }
      let { data } = await this._api.get({ type: 'entity', payload })
      let nft = new NftCatalog(data.nft)
      let triggers = data.triggers || []
      let tokens = data.hasOwnProperty('tokens') ? data.tokens.map(value => new ProductNftToken(value)) : null
      return { nft, triggers, tokens }
    } catch(e) {
      throw e
    }
  }
}
