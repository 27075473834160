/**
 * @class
 */
export default class Address {

  /**
   * @param {Object} value
   * @returns {Address}
   * @static 
   */
  static format(value){
    let country = value.country_name != null ? value.country_name : value.country;
  
    return new Address({
      city: value.city,
      country,
      countryId: value.country_id || value.countryId || null,
      id: value.id || null,
      number: value.num || value.number,
      isDefault: value.is_default || value.isDefault || false, 
      street: value.street,
      zip: value.zip
    })
  }

  ///////////////////////////////////////////////////////////////////

  /**
   * @constructor
   * @param {Object} options
   * @param {String} options.city
   * @param {String} options.country
   * @param {String} options.id
   * @param {Boolean} options.isDefault
   * @param {String} options.number
   * @param {String} options.street
   * @param {String} options.zip
   */
  constructor({city, country, countryId, id, isDefault, number, street, zip}){
    this._city = city;
    this._country = country;
    this._countryId = countryId;
    this._id = id;
    this._isDefault = isDefault;
    this._number = number;
    this._street = street;
    this._zip = zip;
  }
  
  /////////////////////////////////////////
  ///           GETTERS
  /**
   * @property {String}
   * @readonly
   */
  get city(){
    return this._city;
  }

  /**
   * @property {String}
   * @readonly
   */
  get country(){
    return this._country
  }

  /**
   * @property {String}
   * @readonly
   */
  get countryId(){
    return this._countryId
  }

  /**
   * @property {String}
   * @readonly
   */
  get id(){
    return this._id;
  }

  /**
   * @property {Boolean}
   */
  get isDefault() {
    return this._isDefault
  }

  /**
   * @param {Boolean} value
   */
  set isDefault(value) {
    this._isDefault = value
  }

  /**
   * @property {String}
   * @readonly
   */
  get number(){
    return this._number
  }

  /**
   * @property {String}
   * @readonly
   */
  get street(){
    return this._street
  }

  /**
   * @property {String}
   * @readonly
   */
  get zip(){
    return this._zip
  }

  /////////////////////////////////////////
  ///           METHODS
  toString(){
    let ret = '';
    let hasStreet = this.number && this.street;
    let hasZip = this.zip;
    let hasCity = this.city;

    if (hasStreet){
      ret += `${this.street}, ${this.number}` 
    }

    if (hasStreet && (hasZip || hasCity)){
      ret += ' - ';
    }

    if (hasZip){
      ret += `${this.zip} `;
    }

    if (hasCity){
      ret += this.city
    }
    
    return ret;
  }

  toAPI() {
    return {
      street: this.street,
      city: this.city,
      number: this.number,
      country: this.country,
      country_id: this.countryId,
      id: this.id, 
      zip: this.zip,
      is_default: this.isDefault
    }
  }
}
