import { basil } from '@spices/basil'
import { CurryApi } from '@spices/curry'

import config from './config'
import Error from '../../error'

/**
 * @class
 */
export default class VoucherController {
	/**
	 * @constructor
	 * 
	 * @param {Object} options
	 * @param {Object} options.logger - Logger we use in all the app. In the view $console and here logger. See @spices/cayenne
	 * @param {CheckoutController} options.parent
	 * @param {Object} options.store - Checkout store
	 * @param {Object} options.transports - Object containing cp and HTTP. See @/bootstrap/data/api/transports
	 */
  constructor({ logger, parent, store, transports }){
    this._api = new CurryApi({ config: config, transports });
		this._logger = logger
		this._parent = parent
		this._store = store
	}
  
  /////////////////////////////////////////
  ///           GETTERS
	/**
	 * @property {String}
	 * @readonly
	 */
	get code() {
		return this._store().code
	}

	/**
	 * @property {Voucher}
	 * @readonly
	 */
	get voucher() {
		return this._store().voucher
	}


  /////////////////////////////////////////
  ///           METHODS
	/**
	 * Add a voucher to the order
	 *
	 * @async
	 * @param {Object} options
	 * @param {String} options.code
	 * 
	 * @returns {Object}
	 */
	async add({ code }) {
		try {
			if(basil.isNil(this._parent.order)) {
				 throw Error.ORDER_NOTFOUND
			}

			let { data } = await this._api.post({ type: 'entity', payload: { order_id: this._parent.order.id, item: { code }}})
			
			let voucher = basil.get(data, 'voucher', null)
			this._store().voucher = voucher
			this._parent._setOrder(data)

			return this.voucher
		} catch(e) {
			throw e
		}
	}

	/**
	 * Clear a voucher from the order
	 *
	 * @async
	 */
	async clear() {
		try {
			if(basil.isNil(this._parent.order.voucher)) {
				return resolve()
			}

			let { data } = await this._api.delete({ type: 'entity', payload: { order_id: this._parent.order.id }})
			
			this._store().voucher = null
			this._parent._setOrder(data)
		} catch(e) {
			throw e
		}
	}
}
