import Service from "./services/model"

export default {
  address: {
    source: 'address',
    type: Object
  },
  banners: {
    format: value => {
      if (!value || value == null || value.length === 0) {
        return null;
      }

      let ret = value.filter(i => i.field_name === 'banner');

      if (ret && ret.length > 0) {
        ret = ret.map(i => i.url);
      }

      return ret ? ret : null;
    },
    source: 'images',
    type: Array
  },
  contactEmail: {
    source: 'contact_email',
    type: String
  },
  currency: {
    source: 'currency',
    type: Object
  },
  currencyCode: {
    source: 'currency_code',
    type: String
  },
  customerId: {
    source: 'customer_id',
    type: String
  },
  description: {
    source: 'descr',
    type: String
  },
  descrMini: {
    source: 'descr_mini',
    type: String
  },
  facebookPage: {
    source: 'facebook_page',
    type: String
  },
  fulfillmentLocations: {
    source: 'fulfillment_locations',
    type: Array
  },
  hasTimeslots: {
    source: 'has_timeslots',
    type: Object
  },
  id: {
    source: 'id',
    type: String
  },
  images: {
    source: 'images',
    type: Array
  },
  instagramPage: {
    source: 'instagram_page',
    type: String
  },
  loc: {
    source: 'loc',
    type: Object
  },
  logo: {
    format: value => {
      if (!value || value == null || value.length === 0) {
        return null;
      }

      let ret = value.find(i => i.field_name === 'logo');

      if (ret) {
        ret = ret.url;
      }

      return ret ? ret : null;
    },
    source: 'images',
    type: String
  },
  messengerPage: {
    source: 'messenger_page',
    type: String
  },
  phoneNumber: {
    source: 'phone_number',
    type: String
  },
  pinterestPage: {
    source: 'pinterest_page',
    type: String
  },
  position: {
    source: 'position',
    type: Number
  },
  services: {
    format: (value) => {
      let ret = []
      Object.keys(value).forEach(s => {
        value[s].name = s
        ret.push(new Service(value[s]))
      })
      return ret
    },
    source: 'services',
    type: Array
  },
  slug: {
    source: 'slug',
    type: String
  },
  status: {
    source: 'status',
    type: String
  },
  timezone: {
    source: 'timezone',
    type: String
  },
  name: {
    source: 'title',
    type: String
  },
  twitterPage: {
    source: 'twitter_page',
    type: String
  },
  website: {
    source: 'website',
    type: String
  },
  youtubePage: {
    source: 'youtube_page',
    type: String
  }
}
