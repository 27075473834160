import App from '@/App'
import Pepper from '@spices/pepper'
import { basil as $basil, install as Basil } from '@spices/basil'

import { createPinia, PiniaVuePlugin } from 'pinia'

import { install as basilI18n } from '@spices/basil-ic-i18n'
import phonenumber from '@/helpers/phonenumber'

export default async ({ args, core, eventbus, i18n, logger, router, store, Vue }) => {
  logger.group('bootstrap.app')

  // Plugins
  $basil.global = true
  $basil.use(basilI18n)

  // Pinia
  const pinia = createPinia()
  Vue.use(PiniaVuePlugin)

  Vue.use(Pepper)
  Vue.use(Basil)

  // Accessors
  Object.defineProperty(Vue.prototype, '$bus', {
    get: () => eventbus
  })

  // Global filters
  Vue.filter('phonenumber', phonenumber)
  
  new Vue({
    router,
    pinia: pinia,
    i18n: i18n.i18n,
    provide(){
      return {
        '$core': core,
        '$catalog': core.catalog,
        '$checkout': core.checkout,
        '$embed': core.embed,
        '$localStorage': core.localStorage,
        '$nft': core.nft,
        '$user': core.user,
      }
    },
    render: h => h(App)
  }).$mount('#app')

  store.bootstrap().args = args

  logger.groupEnd('bootstrap.app')
  return
}
