export default {
  created: {
    source: 'id',
    type: Date
  },
  id: {
    source: 'id',
    type: String
  },
  isoCode2: {
    source: 'iso_code_2',
    type: String
  },
  isoCode3: {
    source: 'iso_code_3',
    type: String
  },
  modified: {
    source: 'modified',
    type: Date
  },
  name: {
    source: 'name',
    type: String
  },
  postalCodes: {
    source: 'postal_codes',
    type: Array
  }
}