<template>
  <layout-modal
    v-bind="attrs"
    v-on="listeners">
    <div
      class="modal-terms__loader"
      v-if="loading">
      <ui-loader />
    </div>

    <iframe
      class="modal-terms__iframe"
      height="100%"
      ref="terms"
      loading="lazy"
      :src="url"
      width="100%"
      v-if="!hasTerms"
    />

    <div 
      class="modal-terms__placeholder" 
      v-html="termsContent"
      v-if="hasTerms">
    </div>
  </layout-modal>
</template>

<script>
import Config from '@/config'
import SaylStore from '@/bootstrap/stores'
import LayoutModal from '@/components/layouts/modal'

export default {
  name: 'TermsModal',

  components: {
    LayoutModal
  },

  inject: [
    '$embed',
    '$user',
  ],

  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      bootstrap: SaylStore.bootstrap(),
      loading: true,
    }
  },

  computed: {
    attrs() {
      return {
        classes: {
          'modal-terms': true,
        },
        loading: this.loading,
        title: this.$t('resto.modal_terms_title'),
        visible: this.visible,
      }
    },

    hasTerms() {
      return !this.$basil.isNil(this.terms)
    },

    listeners() {
      return {
        close: this.back,
        back: this.back,
        discard: this.back,
      }
    },
    
    placeholders() {
      return this.$basil.get(this.$embed, 'embed.model.placeholders', null)
    },

    terms() {
      return this.placeholders.find(placeholder => placeholder.key === 'terms')
    },

    termsContent() {
      if(!this.hasTerms) {
        return null
      }

      let lang = this.$basil.get(this.user, 'lang')
      let content = this.$basil.get(this.terms, `content.${lang}`, this.$basil.get(this.terms, 'content.all'))

      return content
    },

    url() {
      let ret = Config.terms
      let url = this.$basil.get(this.bootstrap.settings, 'custom_tc_url')

      return !this.$basil.isNil(url) ? url : ret
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    userLang() {
      return this.$basil.get(this.user, 'lang')
    }
  },

  methods: {
    back() {
      this.$emit('close')
    },
  },

  mounted() {
    this.loading = true
    let ref = this.$refs.terms

    if(!this.$basil.isNil(ref) && !this.hasTerms) {
      let ctx = this

      ref.addEventListener('load', () => {
        ctx.loading = false
      })
    } else {
      this.loading = false
    }
  },
}
</script>
