import { isStaging, isProduction } from './config'

if(isStaging()) {
  __webpack_public_path__ = 'https://cdn-apps.sayl.cloud/staging/conn3ct-store/'
}
if(isProduction()) {
  __webpack_public_path__ = 'https://cdn-apps.sayl.cloud/conn3ct-store/'
}

import Vue from 'vue'
import VueBootstrap from '@/bootstrap/'
import VueScrollReveal from 'vue-scroll-reveal'
import '@/styles/app.scss'
import * as svgs from './icons'

Vue.config.productionTip = false

Object.keys(svgs).forEach(key => {
  Vue.component(key, svgs[key])
})

Vue.use(VueScrollReveal, {
  class: 'v-scroll-reveal', // A CSS class applied to elements with the v-scroll-reveal directive useful for animation overrides.
  duration: 800,
  scale: .98,
  distance: '10px',
})

Vue.use( VueBootstrap )
