import { basil } from '@spices/basil'
import { CurryModel } from '@spices/curry'
import manifest from './manifest'

/** 
 * @class
 * @author Valentin Gregoire <valentin@infinity-mobile.io>
 */
export default class Web3Voucher extends CurryModel {
  /**
   * @constructor
   */
  constructor(data = null) {
    super({ data, manifest })
  }

  /////////////////////////////////////////
  ///           GETTERS
  get isRecurring() {
    return basil.get(this.generation, 'isRecurring', false)
  }

  get onClaim() {
    return basil.get(this.generation, 'onClaim', false)
  }
}
