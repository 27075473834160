import { CurryModel } from '@spices/curry'
import manifest from './manifest'

/** 
 * @class
 * @author Valentin Gregoire <valentin@infinity-mobile.io>
 */
export default class PaymentMethod extends CurryModel {
  /**
   * @constructor
   */
  constructor(data = null) {
    super({ data, manifest })
  }
}
