import { CurryApi } from '@spices/curry'

import Config from './config'
import Catalog from './models/model'

/**
 * @class
 */
export default class CatalogController {
  /**
   * @constructor
   * 
   * @param {Object} options
   * @param {Object} options.logger - Logger we use in all the app. In the view $console and here logger. See @spices/cayenne
   * @param {Object} options.store - Store for the Catalog management
   * @param {Object} options.transports - Object containing cp and HTTP. See @/bootstrap/data/api/transports
   */
  constructor({ logger, store, transports }) {
    this._api = new CurryApi({ config: Config, transports })
    this._logger = logger
    this._store = store
  }

  /////////////////////////////////////////
  ///           GETTERS
  /**
   * @property {Catalog}
   * @readonly
   */
  get catalog() {
    return this._store().catalog
  }

  /**
   * @property {Category}
   * @readonly
   */
  get category() {
    return this._store().category
  }

  /**
   * @property {Array<Category>}
   * @readonly
   */
  get categories() {
    return this._store().categories
  }

  /////////////////////////////////////////
  ///           INIT
  /**
   * Iniritalize the catalog
   * 
   * @async
   * @param {Object} options
   * @param {Object} options.catalog
   * 
   * @returns {Catalog}
   */
  async init({ catalog }) {
    try {
      this._logger.debug('catalog', catalog)

      this._store().catalog = new Catalog(catalog)
      this._store().categories = this.catalog.categories.map(c => c.copy())

      return this.catalog
    } catch(e) {
      throw e
    }
  }

  /////////////////////////////////////////
  ///           METHODS
  /**
   * Fetch the data from the server
   * 
   * @async
   * @param {Object} options
   * @param {String} options.id
   * @param {String} options.locale
   * @param {String} options.service
   * 
   * @returns {Object}
   */
  async find({ id, locale, service }) {
    try {
      service = service === 'pickup' ? 'base' : service
      this._logger.info('catalog.catalog.fetch', id, locale, service)

      let { data } = await this._api.get({ type: 'entity', payload: { id, locale, service }})
      return data      
    } catch(e) {
      throw e
    }
  }

  /**
   * Filter the categories
   * 
   * @returns {Catalog}
   */
  filter() {
    this._logger.info('catalog.catalog.filter')
    this.catalog.categories = this.categories.filter(c => c.visible === true)
    return this.catalog
  }
  
  /**
   * Link the catalog with the given products
   * 
   * @param {Object} options 
   * @param {Array} options.products 
   */
  link([catalog, products], reset = false) {
    this._logger.info('catalog.catalog.link')

    if(reset === true) {
      this.categories.forEach(c => c.products = [])
    }

    products.forEach(p => {
      let c = this.categories.find(c => c.id === p.categoryId)

      if(c) {
        p.category = c
        c.products.push(p)
        c.products = c.products.sort((a, b) => a.position - b.position)
      }
    })
  }
}

