import { basil } from '@spices/basil'
import { CurryApi } from '@spices/curry'
import config from './config'
import Error from '../error'

import { useCatalogStore } from '../catalogs/store'
import { useCheckoutStore } from './store'
import { useEmbedStore } from '../embed/store'

import PaymentController from './payment-methods/controller'
import VoucherController from './vouchers/controller'

import Order from './models/order'

/**
 * @class
 */
export default class CheckoutMasterController {
  /**
   * @constructor
   * 
   * @param {Object} options
   * @param {String} options.locale - locale for the data fetching
   * @param {Object} options.logger - Logger we use in all the app. In the view $console and here logger. See @spices/cayenne
   * @param {Object} options.transports - Object containing cp and HTTP. See @/bootstrap/data/api/transports
   */
  constructor({ locale, logger, transports }) {
    this._api = new CurryApi({ config: config, transports })
    this._logger = logger
    this._store = useCheckoutStore
    
    let args = {
      locale,
      logger,
      parent: this,
      store: this._store,
      transports,
    }

		this._payment = new PaymentController(args)
		this._voucher = new VoucherController(args)
  }
  
  /////////////////////////////////////////
  ///           GETTERS
  /**
   * @property {Boolean}
   * @readonly
   */
  get loading() {
    return this._store().loading
  }

  /**
   * @property {Order} order
   * @readonly
   */
  get order() {
    return this._store().order
  }

  /**
   * @property {PaymentMethodsController} payment
   * @readonly
   */
  get payment() {
    return this._payment
  }

  /**
   * @property {Pinia} 
   */
  get store() {
    return this._store
  }

	/**
	 * @property {VoucherController} voucher
   * @readonly
	 */
	get voucher() {
		return this._voucher
	}
	
  /////////////////////////////////////////
  ///           METHODS
	 /**
   * Set the given order information as the current order to use
   *
   * @param {Order} order
   * @private
   */
   _setOrder(order) {
    if(!order) {
      return
    }

    if(!(order instanceof Order)) {
      order = new Order(order)
    }

    this._logger.info('checkout.setOrder')
    this._store().order = order
  }

  async cancel() {
    try {
      if(!this.order || !this.order.isCancellable) {
        return
      }

      await this._api.delete({ type: 'entity', payload: { order_id: this.order.id }})
      return
    } catch(e) {
      throw e
    }
  }

  /**
	 * Confirm the order
	 */
	confirm() {
    this._logger.info('checkout.confirm')
    document.location.href = this.payment.trigger.url
	}

  /**
   * Create a direct checkout based on the product id
   * It is made for this project specificaly
   * 
   * Allow to checkout one item with type NFT
   * 
   * @async
   * @param {Object} options
   * @param {String} options.embedId
   * @param {Array} options.items
   * @param {Object} options.product
   * @param {String} options.service
   * 
   * @returns {Order}
   */
  async directCheckout({ embed, items, product, service }) {
    try {
      this._logger.info('checkout.direct');

      let { data } = await this._api.post({
        type: 'entity',
        payload: {
          item: {
            items,
            product,
            service,
            shop_id: basil.get(embed, 'shop.model.id'),
            embed_id: basil.get(embed, 'embed.model.id')
          }
        }
      })

      this._setOrder(data)
      return this.order
    } catch(e) {
      throw e
    }
  }

  /**
	 * Add the given comment to the order
	 *
   * @async
	 * @param {Object} options
	 * @param {String} options.comment - The value to add as comment
   */
  async updateAuthInfo({ comment }) {
		try {
			this._logger.info('checkout.comment')
      this._logger.debug('-comment', comment)

      comment = comment != null ? comment.trim() : comment

      if(comment != null) {
        await this._api.post({ 
          type: 'collection', 
          payload: { 
            order_id: this.order.id, 
            item: { 
              comment: basil.isEmpty(comment) ? null : comment 
            }
          }
        })
      }

      await this._api.put({ payload: { order_id: this.order.id }})
		} catch(e) {
      throw e
    }
	}

  /**
	 * Load the order by its id
	 *
   * @async
	 * @param {Object} options
	 * @param {String} options.id
	 */
	async view({ orderId }) {
		try {
			this._logger.info('checkout.view')

			let { data } = await this._api.get({ type: 'entity', payload: { order_id: orderId }})
      this._setOrder(data)

      if(!basil.isNil(window.gtag)) {
        let shop = useEmbedStore().shop 
        let catalog = useCatalogStore().catalog 
        let payload = this.order.toGtagAPI({ catalog, isCheckout: false, shop })
        
        gtag('event', 'purchase', payload)
      }
		} catch(e) {
      if(basil.get(e, 'response.status') === 404){
        throw Error.ORDER_NOTFOUND
      }
      throw e
    }
	}
}
