export default {
  fqn: 'ifm.checkout',
  endpoints: {
    collection: {
      post: '/checkout/$order_id/comment'
    },
    
    entity: {
      get: '/confirm/$order_id',
      post: '/checkout',
      put: '/checkout/$order_id/user',
      delete: '/checkout/$order_id',
    },

    flpos: {
      put: '/checkout/$order_id/fulfillment-location'
    },

    voucher: {
      post: '/checkout/$order_id/voucher',
      delete: '/checkout/$order_id/voucher'
    },

    loyalty: {
      post: '/checkout/$order_id/credits'
    },

    fees: {
      get: '/fees/$order_id'
    },

    feedback: {
      get: '/feedback/$token',
      post: '/feedback/$token'
    },

    transport: 'cp'
  },
  transport: 'cp'
}
