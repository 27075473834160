import { basil } from '@spices/basil'
import { defineStore } from 'pinia'

export const useCheckoutStore = defineStore('checkout', {
  state: () => ({
    // Datas
    order: null,

      // Payments
      paymentMethod: null,
      paymentMethods: null,
      paymentRedirect: null,
      paymentTrigger: null,

      // Vouchers
      voucher: null,

    // Status
    loading: true,

    // Cards management
    ...JSON.parse(JSON.stringify(defaultCardState))
  }),

  getters: {
    actives: (state) => {
      let cards = [ state.authCard, state.paymentMethodCard, state.voucherCard ]
      let pm = state.paymentMethod
      let excludeVoucher = []

      if(pm && pm.slug && pm.slug === 'invoice') {
        excludeVoucher = !basil.get(pm, 'metadata.requires_voucher', false)
      }

      return cards.filter(e => {
        let ret = e.active === true && e.name !== 'voucher'

        if(!excludeVoucher) {
          ret = e.active === true || e.valid === false
          if(e.valid === false) {
            state.voucher.errors = ['conn3ct-store.voucher_is_required_for_invoice']
          }
        }

        return ret
      }).map(e => e.name)
    }
  },

  actions: {
    reset: (state) => {
      state.auth = JSON.parse(JSON.stringify(defaultCardState.auth))
      state.paymentMethod = JSON.parse(JSON.stringify(defaultCardState.paymentMethod))
      state.term = JSON.parse(JSON.stringify(defaultCardState.term))
      state.voucher = JSON.parse(JSON.stringify(defaultCardState.voucher))
    }
  }
})

const defaultCardState = {
  authCard: {
    active: true,
    errors: {},
    loading: false,
    name: 'auth',
    pristine: true,
    required: false,
    valid: false,
  },

  paymentMethodCard: {
    active: false,
    errors: [],
    loading: false,
    name: 'paymentMethods',
    pristine: true,
    required: false,
    valid: false,
  },

  termCard: {
    active: true,
    errors: [],
    loading: false,
    name: 'term',
    pristine: true,
    required: false,
    valid: false,
  },

  voucherCard: {
    active: false,
    errors: [],
    loading: false,
    name: 'voucher',
    pristine: true,
    required: false,
    valid: true,
  },
}
