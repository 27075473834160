<template>
  <div :class="classes">
    <ui-icon 
      class="ui-connectivity__icon" 
      :glyph="icon" 
      v-if="!connected" 
    />

    <div
      class="ui-connectivity__label"
      v-if="!connected" 
    >{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: 'UiConnectivity',

  data() {
    return {
      connected: true
    }
  },

  computed: {
    classes() {
      return {
        'ui-connectivity': true,
        '-offline': !this.connected,
        '-online': this.connected
      }
    },

    icon() {
      return this.connected === true ? 'wifi' : 'disconnected'
    },

    label() {
      return this.$t('conn3ct-store.network_offline')
    }
  },

  methods: {
    update() {
      this.connected = navigator.onLine
    }
  },

  created() {
    window.addEventListener('online', this.update)
    window.addEventListener('offline', this.update)

    this.update()
  },

  destroyed() {
    window.removeEventListener('online', this.update)
    window.removeEventListener('offline', this.update)
  },
}
</script>
