import ErrorsRoutes from './errors'
import { isDevelopment } from '../../config'

const routes = [
  isDevelopment() ? {
    name: 'Translations',
    path: '/translations',
    component: () => import('./views/translations/missing.vue'),
    meta: {
      bodyClass: 'view-user-home layout-module-users',
      scrollTop: true,
      header: true,
      footer: true
    }
  } : null
].filter(r => r != null)

export default routes.concat(ErrorsRoutes)
