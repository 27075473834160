import { basil } from '@spices/basil'
import { CurryApi } from '@spices/curry'
import config from './config'
import Error from '../../error'

import Country from './models/countries/model'
import Shop from './models/model'

/** * 
 * @class
 */
export default class ShopController {
  /**
   * @constructor
   * 
   * @param {Object} options
   * @param {Object} options.logger - Logger we use in all the app. In the view $console and here logger. See @spices/cayenne
   * @param {Object} options.store - Store for the Embed management
   * @param {Object} options.transports - Object containing cp and HTTP. See @/bootstrap/data/api/transports
   */
  constructor({ logger, store, transports }) {
    this._api = new CurryApi({ config: config, transports })
    this._logger = logger
    this._store = store
  }

  /////////////////////////////////////////
  ///           GETTERS
  /**
   * @property {Array}
   * @readonly
   */
  get countries() {
    return this._store().countries || null
  }

  /**
   * @property {Shop}
   * @readonly
   */
  get model() {
    return this._store().shop
  }

  /////////////////////////////////////////
  ///           INIT
  /**
   * Initialize the Shop controller
   * 
   * @async 
   * @param {Object} options
   * @param {String} options.id
   * @param {String} options.locale
   * @param {Object} options.shop
   * 
   * @returns {Shop}
   */
  async init({ id, locale, shop }) {
    try {
      this._logger.info('embed.shop.init')

      if(shop) {
        this._store().shop = new Shop(shop)
      } else {
        let { data } = await this._api.get({ type: 'entity', payload: { id, locale }})
        this._store().shop = new Shop(data)
      }

      if( this.model.status !== 'published') {
        throw(Error.SHOP_NOT_PUBLISHED)
      }

      await this.getCountries({ locale })
      return this.model
    } catch(e) {
      $console.error(e)
      throw e
    }
  }


  /////////////////////////////////////////
  ///           METHODS
   /**
   * Excude the given services from the shop 
   * 
   * @param {Object} options 
   * @param {Array} options.excluded 
   */
  filterServices({ excluded }){
    this._logger.group('embed.shop.filterServices')
    this._logger.debug(this.model.services)

    this.model.services = this.model.services.filter(s => {
      this._logger.debug('-', s.name, excluded.includes(s.apiname) ? 'x' : 'v')
      return !excluded.includes(s.apiname)
    })

    this._logger.groupEnd('embed.shop.filterServices')
  }

  /**
   * Load the list of countries
   * 
   * @async
   * @private
   * @param {Object} options
   * @param {Shop} options.shop
   * @param {String} options.locale
   * 
   * @returns {Array<Country>}
   */
  async getCountries({ shop = this.model, locale }) {
    try {
      this._logger.info('embed.shop.getCountries')

      if(basil.isNil(this.countries)) {
        let { data } = await this._api.get({ type: 'countries', payload: { shop_id: shop.id, locale }})
        this._store().countries = data.map(c => new Country(c))
      }

      return this.countries
    } catch(e) {
      throw e
    }
  }
}
