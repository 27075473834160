import { defineStore } from 'pinia'

export const useEmbedStore = defineStore('embed', {
  state: () => ({
    // Datas
    countries: [],
    embed: null,
    service: null,
    services: null,
    shop: null,

    // Status
    loading: true,
  }),
})