import { defineStore } from 'pinia'

export const useCatalogStore = defineStore('catalog', {
  state: () => ({
    // Datas
    catalog: null,
    category: null,
    categories: null,
    products: null,
    product: null,

    // Status
    loading: true,
  }),
})