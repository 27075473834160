<template>
  <div
    id="app"
    :class="classes">
    <!-- Connectivity -->
    <ui-connectivity />

    <!-- Loading -->
    <section
      class="app-loader"
      ref="app-loader"
      v-if="isLoading && !isErrored">
      <div class="app-loader__body">
        <a
          href="https://sayl.finance"
          class="app-loader__cta"
          target="_blank">
          <div class="app-loader__logo">
            <svg-sayl-color class="app-loader__logo-svg" />
          </div>
        </a>

        <div class="app-loader__loader">
          <div class="app-loader__bara"></div>
          <div class="app-loader__barb"></div>
          <div class="app-loader__barc"></div>
          <div class="app-loader__bard"></div>
          <div class="app-loader__bare"></div>
          <div class="app-loader__barf"></div>
        </div>
      </div>
    </section>

    <!-- Loading Error -->
    <error-view
      :errors="bootstrap.errors"
      v-if="isErrored" 
    />

    <!-- Application -->
    <template v-if="!isLoading">
      <!-- Header -->
      <nav-header />
      <!-- <nav-burger /> -->

      <!-- Content -->
      <router-view />

      <!-- Footer -->
      <nav-footer />

      <modal-custom />

      <modal-age-disclaimer
        :visible="showAgeDisclaimer"
        @confirm="() => key++"
        v-if="showAgeDisclaimer"
      />

      <modal-terms
        :visible="showTerms"
        @close="() => showTerms = false"
        v-if="showTerms"
      />

      <modal-cookies />
    </template>
  </div>
</template>

<script>
import SaylStore from '@/bootstrap/stores'

import ErrorView from '@/modules/errors/error'

import ModalAgeDisclaimer from '@/components/modals/age-disclaimer'
import ModalCookies from '@/components/modals/cookies.vue'
import ModalCustom from '@/components/modals/custom'
import ModalTerms from '@/components/modals/terms'

import NavHeader from '@/components/navigations/header'
import NavFooter from '@/components/navigations/footer'

import UiConnectivity from '@/components/ui/connectivity'

export default {
  name: 'SaylConn3ctStore',

  components: {
    ErrorView,

    ModalAgeDisclaimer,
    ModalCookies,
    ModalCustom,
    ModalTerms,

    NavHeader,
    NavFooter,

    UiConnectivity,
  },

  inject: [
    '$embed', 
    '$localStorage'
  ],

  data() {
    return {
      key: 1,
      bootstrap: SaylStore.bootstrap(),
      showTerms: false
    }
  },

  computed: {
    classes() {
      return {
        '-is-loading': this.isLoading,
        '-is-active': !this.isLoading,
        '-is-restricted': this.showAgeDisclaimer
      }
    },

    isLoading() {
      return [
        this.bootstrap.states.APPLICATION_INIT, 
        this.bootstrap.states.APPLICATION_AUTH, 
        this.bootstrap.states.APPLICATION_MODULES
      ].includes(this.bootstrap.status)
    },

    isErrored() {
      return this.bootstrap.errors && this.bootstrap.errors.length > 0
    },

    showAgeDisclaimer() {
      let embedId = this.$basil.get(this.$embed, 'embed.model.id')
      var currentDate = new Date()
      var disclaimer = JSON.parse(window.localStorage.getItem('ageDisclaimer_' + embedId))
      let value = !this.$basil.isNil(disclaimer) ? disclaimer.value : false

      if(this.$basil.isNil(disclaimer)) {
        value = !this.$basil.get(this.$embed, 'embed.model.ageDisclaimer', false)
      } else {
        var expirationDate = disclaimer.expiresAt

        if(Date.parse(currentDate) > Date.parse(expirationDate)) {
          window.localStorage.removeItem('ageDisclaimer_'+embedId)
          value = !this.$basil.get(this.$embed, 'embed.model.ageDisclaimer', false)
        }
      }

      return !this.isLoading && this.key && (value === false || this.$basil.isNil(value))
    },
  },
  
  methods: {
    resize() {
      setTimeout(() => {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      }, 250)
    },
  },

  mounted() {
    this.resize()
    window.addEventListener('resize', this.resize)
    this.$bus.$on('terms', () => {
      this.showTerms = true
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
    this.$bus.$off('terms')
  },
}
</script>
