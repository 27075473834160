import { CurryModel } from '@spices/curry'
import manifest from './manifest'

/** 
 * @class
 * @author Valentin Gregoire <valentin@infinity-mobile.io>
 */
export default class Service extends CurryModel {
  /**
   * @constructor
   */
  constructor(data = null) {
    super({ data, manifest })

    this.catalog = null
  }

  get apiname() {
    return this.name
  }

  /**
   * Whether or not the shop is closed now
   * @property {Boolean}
   * @readonly
   */
  get isClosed(){
    return !this.isOpen
  }

  /**
   * Whether or not the shop is open now
   * @property {Boolean}
   * @readonly
   */
  get isOpen(){
    if(!this.schedule){
      return false
    }

    let now = new Date().getTime()

    let ret = this.schedule.map((s) => {
      let [fh, fm] = s.from.split(':')
      let from = new Date()
      from.setHours(fh)
      from.setMinutes(fm)
      from = from.getTime()

      let [th, tm] = s.to.split(':')
      let to = new Date()
      to.setHours(th)
      to.setMinutes(tm)
      to = to.getTime()

      return from <= now && to >= now
    })

    return ret.filter(r => r === true).length > 0
  }

  /**
   * Is the shop currently closed but will open later today
   * @property {Boolean}
   * @readonly
   */
  get isOpenLater(){
    if (!this.schedule){
      return false
    }
    
    let now = new Date().getTime()
    let ret = this.schedule.map(s => {
      let [fh, fm] = s.from.split(':')
      let from = new Date()
      from.setHours(fh)
      from.setMinutes(fm)
      from = from.getTime()
      
      return from >= now
    })
    
    return ret.filter(r => r === true).length > 0
  }

  /**
   * Today's schedule of openning
   * @property {Array}
   * @readonly
   */
  get schedule(){
    let d = new Date().getDay()
    
    return  this.availabilitySchedule ?
            this.availabilitySchedule.filter(s => s.day === d) :
            null
  }
}
