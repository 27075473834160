import { CurryModel } from '@spices/curry'
import manifest from './manifest'

import { basil } from '@spices/basil'
import Config from '@/config.js'
import { Error } from '../../..'

/** 
 * @class
 * @author Valentin Gregoire <valentin@infinity-mobile.io>
 */
export default class Shop extends CurryModel {
  /**
   * @constructor
   */
  constructor(data = null) {
    super({ data, manifest })
  }

  get service() {
    if(this.services == null || this.services.length === 0) {
      return Error.SERVICE_NOTFOUND
    }

    return this.services.find(s => s.apiname === 'virtual')
  }

  get socials() {
    let baseLink = basil.get(Config, 'icons', 'https://cdn-apps.sayl.cloud/user/statics/images/icons/social/')

    return [
      this.facebookPage ? {
        type: 'facebook',
        href: this.facebookPage.includes('facebook.com') ? 
          this.facebookPage :
          `https://facebook.com/${this.facebookPage}`,
        icon: `${baseLink}facebook.svg`
      } : null,

      this.instagramPage ? {
        type: 'instagram',
        href: this.instagramPage.includes('instagram.com') ? 
          this.instagramPage :
          `https://instagram.com/${this.instagramPage}`,
        icon: `${baseLink}instagram.svg`
      } : null,
      
      this.messengerPage ? {
        type: 'messenger',
        href: this.messengerPage.includes('messenger.com') ? 
          this.messengerPage :
          `https://messenger.com/${this.messengerPage}`,
        icon: `${baseLink}messenger.svg`
      } : null,

      this.pinterestPage ? {
        type: 'pinterest',
        href: this.pinterestPage.includes('pinterest.com') ? 
          this.pinterestPage :
          `https://pinterest.com/${this.pinterestPage}`,
        icon: `${baseLink}pinterest.svg`
      } : null,
      
      this.twitterPage ? {
        type: 'twitter',
        href: this.twitterPage.includes('twitter.com') ? 
          this.twitterPage :
          `https://twitter.com/${this.twitterPage}`,
        icon: `${baseLink}twitter.svg`
      } : null,
      
      this.youtubePage ? {
        type: 'youtube',
        href: this.youtubePage.includes('youtube.com') ? 
          this.youtubePage :
          `https://youtube.com/@${this.youtubePage}`,
        icon: `${baseLink}youtube.svg`
      } : null,
    ].filter(s => s != null)
  }
}
