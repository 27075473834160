import { CurryApi } from '@spices/curry'
import config from './config'

import { useUserStore } from './store'

import User from './models/model'

/**
 * @class
 */
export default class UserController {
  /**
   * @constructor
   * 
   * @param {Object} options
   * @param {LocalStorageController} options.localStorage - LocalStorage manager scoped. See @/core/local-storage/controller.js
   * @param {Object} options.logger - Logger we use in all the app. In the view $console and here logger. See @spices/cayenne
   * @param {Object} options.transports - Object containing cp and HTTP. See @/bootstrap/data/api/transports
   */
  constructor({ localStorage, logger, transports }) {
    this._api = new CurryApi({ config, transports })
    this._localStorage = localStorage
    this._logger = logger
    this._store = useUserStore
  }

  /////////////////////////////////////////
  ///           GETTERS
  /**
   * @property {User} user
   * @readonly
   */
  get user() {
    return this._store().user
  }
  
  /////////////////////////////////////////
  ///           INIT
  /**
   * Initialise the user
   * 
   * @async
   * @returns {User}
   */
  async init() {
    try {
      this._logger.info('user.init')    
      this._store().loading = true

      let { data } = await this._api.get({ type: 'entity' })
      this._store().user = new User(data)
      return this.user
    } catch(e) {
      throw e
    } finally {
      this._store().loading = false
    }
  }

  /////////////////////////////////////////
  ///           METHODS
  /**
   * Change on the server the user lang
   *
   * @async
   * @param {Object} locale - Choosen locale to inject in the user
   * 
   * @return {User}
   */
  async changeLang({ locale }) {
    try {
      this._logger.info('user.changeLang')
      this._logger.debug('-lang', locale)

      let { data } = await this._api.put({ type: 'lang', payload: { item: { locale } } })
      this._store().user = new User(data)

      return this.user
    } catch(e) {
      throw e
    }
  }

  /**
   * Reset the current user
   * 
   * @async
   * @returns {User}
   */
  async reset() {
    try {
      this._logger.info('user.reset')

      let locale = this._localStorage.locale
      let { data } = await this._api.post({ type: 'entity', payload: { item: { locale }}})
      this._store().user = new User(data)

      return this.user
    } catch(e) {
      throw e
    }
  }

  /**
   * Update a user with the provided information
   *
   * @async
   * @param {Object} options 
   * @param {Object} options.user
   * 
   * @return {User}
   */
  async update({ user = this.user.toAPI() }) {
    try {
      this._logger.info('user.update')
      
      let { data } = await this._api.put({ type: 'entity', payload: { item: user } })
      this._store().user = new User(data)
      
      return this.user
    } catch(e) {
      throw e
    }
  }
}
