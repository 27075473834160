export default function(value) {
  let values = value.split('').reverse()
  let n = values.length
  let indexes = [1, 3, 5]
  let ret = values.reduce((accumulator, currentValue, index) => {
    return (indexes.includes(index) ? ' ' +currentValue : currentValue) + accumulator 
  })

  return ret
}
