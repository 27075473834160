import { basil } from '@spices/basil'

import Product from '../model'
import ProductNftManifest from './manifest'

/** 
 * @class
 * @author Valentin Gregoire <valentin@infinity-mobile.io>
 */
export default class ProductNft extends Product {
  /**
   * @constructor
   */
  constructor(data = null, manifest = null) {
    super(data, manifest == null ? ProductNftManifest : manifest)
  }

  /////////////////////////////////////////
  ///           GETTERS
  /**
   * Whether or not the product is in promotion
   *  
   * @property {Boolean}
   * @readonly
   */
  get inPromo() {
    return this.promoPrice > 0
  }

  get picture() {
    return [
      this.nftImage || null,
      basil.get(this.nft, 'asset_url', null)
    ].find(s => s != null)
  }

  get vat() {
    return this.vatClass
  }

  get stock() {
    return this.supply - this.lockedSupply
  }

  get supply() {
    if(this.availableSupply) {
      return this.availableSupply
    }

    if(!this.nft || !this.nft.max_supply) {
      return 0
    }

    return this.nft.max_supply
  }

  /////////////////////////////////////////
  ///           GETTERS
  mergeAdditionnalData(product) {
    if(product.nftImage) {
      this.nftImage = product.nftImage
    }

    if(product.nftAttributes) {
      this.nftAttributes = product.nftAttributes
    }

    if(product.hasOwnProperty('nftMintingFromPool')) {
      this.nftMintingFromPool = product.nftMintingFromPool
    }

    if(product.availableSupply > 0) {
      this.availableSupply = product.availableSupply
    } 

    if(product.lockedSupply > 0) {
      this.lockedSupply = product.lockedSupply
    }
  }
}
