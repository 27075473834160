import { CurryApi } from '@spices/curry'
import config from './config'

import Embed from './models/model'

/** 
 * @class
 */
export default class EmbedController {
  /**
   * @constructor
   * 
   * @param {Object} options
   * @param {Object} options.logger - Logger we use in all the app. In the view $console and here logger. See @spices/cayenne
   * @param {Object} options.store - Store for the Embed management
   * @param {Object} options.transports - Object containing cp and HTTP. See @/bootstrap/data/api/transports
   */
  constructor({ logger, store, transports }) {
    this._api = new CurryApi({ config: config, transports })
    this._logger = logger
    this._store = store
  }

  /////////////////////////////////////////
  ///           GETTERS
  /**
   * @property {Embed}
   * @readonly
   */
  get model() {
    return this._store().embed
  }

  /////////////////////////////////////////
  ///           INIT
  /**
   * Initialize the controller
   * 
   * @async
   * @param {Object} options 
   * @param {Object} options.embed 
   * @param {String} options.id 
   * @param {Locale} options.locale 
   * 
   * @returns {Embed}
   */
  async init({ embed, id, locale }) {
    try {
      this._logger.info('embed.embed.init')

      if(embed) {
        this._store().embed = new Embed(embed)
        return this.model
      }

      let { data } = await this._api.get({ type: 'entity', payload: { id, locale }})
      this._store().embed = new Embed(data)
      
      return this.model
    } catch(e) {
      throw e
    }
  }
}
