import { basil } from '@spices/basil'

import CheckoutController from './checkout/controller'
import EmbedController from './embed/controller'
import LocalStorageController from './local-storage/controller'
import NftController from './nfts/controller'
import UserController from './user/controller'
import CatalogMasterController from './catalogs/controller'

/**
 * @class
 * @author Valentin Gregoire <valentin@infinity-mobile.io>
 */
export default class SaylFrontCore {
  /** 
   * @constructor
   * 
   * @param {Object} options
   * @param {Object} options.i18n - i18n controller
   * @param {Object} options.logger - Logger we use in all the app. In the view $console and here logger. See @spices/cayenne
   * @param {Object} options.transports Available transports (eg: Axios, pusher, ...)
   */
  constructor({ i18n, logger, transports }) {
    this._logger = logger
    this._locale = null
    this._args = null
    this._i18n = i18n

    this._localStorage = new LocalStorageController({ logger })
    
    this._catalog = new CatalogMasterController({ localStorage: this.localStorage, logger, transports })
    this._checkout = new CheckoutController({ localStorage: this.localStorage, logger, transports })
    this._embed = new EmbedController({ localStorage: this.localStorage, logger, transports })
    this._nft = new NftController({ localStorage: this.localStorage, logger, transports })
    this._user = new UserController({ i18n, localStorage: this.localStorage, logger, transports })
  }

  /////////////////////////////////////////
  ///           GETTERS
  /**
   * @property {CatalogMasterController}
   * @readonly
   */
  get catalog() {
    return this._catalog
  }

  /**
   * @property {CheckoutController}
   * @readonly
   */
  get checkout() {
    return this._checkout
  }

  /**
   * @property {EmbedController}
   * @readonly
   */
  get embed() {
    return this._embed
  }

  /**
   * @property {LocalStorageController}
   * @readonly
   */
  get localStorage() {
    return this._localStorage
  }

  /**
   * @property {NftController}
   * @readonly
   */
  get nft() {
    return this._nft
  }

  /**
   * @property {UserController}
   * @readonly
   */
  get user() {
    return this._user
  }

  /////////////////////////////////////////
  ///           INIT
  /**
   * Initialize the core of the project and all the needed controllers
   * 
   * @async
   * @param {Object} options
   * @param {Object} options.args
   * @param {Object} options.bootstrap
   */
  async init({ args, bootstrap }) {
    try {
      this._logger.group('core.init', bootstrap)
      
      this._args = args
      
      this._logger.debug('-args', args)
      this._logger.debug('-bootstrap', bootstrap)
      
      let service = this._args.service

      if(basil.isNil(service)) {
        this._args.service = this.localStorage.service
        args.service = this.localStorage.service
        service = this.localStorage.service
      }

      await this._initLocalStorage({ args })
      await this._initUser()
      await this._initEmbed({ args, embed: bootstrap.embed, service, shop: bootstrap.shop })
      await this._initCatalog({ catalog: bootstrap.catalog })
      await this._initNft({ embedId: bootstrap.embed.id })
    } catch(e) {
      throw e
    } finally {
      this._logger.groupEnd('core.init', bootstrap)
    }
  }

  /////////////////////////////////////////
  ///           METHODS
  async _initCatalog({ catalog }) {
    try {
      this._logger.group('core.initCatalog', catalog)
      await this._catalog.init({ catalog })

      return
    } catch(e) {
      throw e
    } finally {
      this._logger.groupEnd('core.initCatalog', catalog)
    }
  }

  /**
   * Init the embed and the shop
   *
   * @async
   * @private
   * @param {Object} options
   * @param {Object} options.embed
   * @param {Object} options.service
   * @param {Object} options.shop
   */
  async _initEmbed({ embed, service, shop }) {
    try {
      this._logger.group('core.initEmbed')

      if(!embed) {
        this._logger.warn('No embed defined')
        this._logger.debug(this._args)
        return {}
      }

      this._logger.debug('- embed', embed)
      this._logger.debug('- service', service)
      this._logger.debug('- shop', shop)

      await this.embed.init({
        args: this._args, 
        embed, 
        locale: this._locale, 
        service, 
        shop,
      })
    } catch(e) {
      throw e
    } finally {
      this._logger.groupEnd('core.initEmbed')
    }
  }

  /**
   * Init the localStorage handling
   * 
   * @async
   * @private
   */
  async _initLocalStorage() {
    try {
      this._logger.group('core.initLocalStorage')
      await this.localStorage.init({ args: this._args })
    } catch(e) {
      throw e
    } finally {
      this._logger.groupEnd('core.initLocalStorage')
    }
  }

  /**
   * Init the NFTs handling
   * 
   * @async
   * @private
   * @param {Object} options
   * @param {String} options.embedId
   */
  async _initNft({ embedId }) {
    try {
      this._logger.group('core.initNft')
      await this.nft.init({ embedId })
    } catch(e) {
      throw e
    } finally {
      this._logger.groupEnd('core.initNft')
    }
  }

  /**
   * Initialize the user (as a guest user)
   * 
   * @async
   * @private
   */
  async _initUser() {
    try {
      this._logger.group('core.initUser')

      let lang = this._args.lang ?
        this._i18n.locales.find(nl => nl.lang.toString() === this._args.lang) :
        null

      lang = !lang ? this._i18n.locale.iso : lang.iso

      await this.user.init({ lang })
    } catch(e) {
      throw e
    } finally {
      this._logger.groupEnd('core.initUser')
    }
  }

  /**
   * Change user lang
   * 
   * @async 
   * @param {Object} options
   * @param {String} options.locale
   */
  async changeLang({ locale }){
    try {
      this._logger.group('core.changeLang')
      await this.user.changeLang({ locale })
      await this.embed.reset({ locale })
      let service = basil.get(this.embed, 'shop.model.service', null)
      if(service) {
        let menuId = service.menuId
        let sName = service.name

        // let c = await this.catalog.catalog.find({ id: menuId, locale, service: sName })
        await this.catalog.init({ catalog: null, id: menuId, locale, service: sName, refresh: true })
        await this.nft.init({ embedId: this.embed.embed.model.id })  
      }
    } catch(e) {
      throw e
    } finally {
      this._logger.groupEnd('core.changeLang')
    }
  }
}
