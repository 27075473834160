export default {
  ORDER_NOTFOUND: {
    code: 300,
    slug: 'order-notfound'
  },
  ORDER_PAYMENT_NOTVALID: {
    code: 301,
    slug: 'order-payement-notvalid'
  },
  SERVICE_NOCATALOG: {
    code: 202,
    slug: 'service-nocatalog'
  },
  SERVICE_NOTACTIVE: {
    code: 201,
    slug: 'service-notactive'
  },
  SERVICE_NOTFOUND: {
    code: 203,
    slug: 'service-notfound'
  },
  SHOP_NOACTIVESERVICE: {
    code: 200,
    slug: 'shop-noactiveservice'
  },
  SHOP_NOT_PUBLISHED: {
    code: 204,
    slug: 'shop-not-published'
  }
}