export default  {
  fqn: 'ifm.user',
  endpoints: {
    lang: {
      put: '/user/language',
    },
    
    entity: {
      get: '/user',
      post: '/user/reset?locale=$locale',
      put: '/user',
    },

    login: { 
      post: '/user/login'
    },

    subscribe: {
      post: '/user/subscribe'
    },

    password: {
      post: '/user/lost-password',
      put: '/user/password',
    },

    resetPassword: {
      post: '/user/reset-password'
    },

    transport: 'cp'
  },
}
