export default {
  description: {
    source: 'descr',
    type: String
  },
  id: {
    source: 'id',
    type: String
  },
  image_id: {
    source: 'image_id',
    type: String
  },
  layout: {
    source: 'layout',
    type: String
  },
  name: {
    source: 'title',
    type: String
  },
  picture: {
    source: 'picture',
    type: String
  },
  position: {
    source: 'position',
    type: Number
  },
  products: {
    source: 'products',
    type: Array
  },
  products_count: {
    source: 'products_count',
    type: Number
  },
  selected: {
    default: false,
    source: 'selected',
    type: Boolean
  },
  slug: {
    source: 'slug',
    type: String
  }
}