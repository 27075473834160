import { defineStore } from 'pinia'

export const useNftStore = defineStore('nft', {
  state: () => ({
    // Datas
    nfts: null,

    // Status
    loading: true,
  }),
})