import { basil } from '@spices/basil'
import { CurryApi } from '@spices/curry'
import Config from './config'
import Error from '../../error'

import PaymentMethod from './models/model'

/**
 * @class
 */
export default class PaymentController {
  /**
   * @constructor
   * 
   * @param {Object} options
   * @param {Object} options.logger - Logger we use in all the app. In the view $console and here logger. See @spices/cayenne
   * @param {CheckoutController} options.parent
   * @param {Object} options.transports - Object containing cp and HTTP. See @/bootstrap/data/api/transports
   */
  constructor({ logger, parent, store, transports, locale }) {
    this._api = new CurryApi({ config: Config, transports })
    this._logger = logger
    this._parent = parent
    this._store = store
    this._locale = locale
  }
  
  /////////////////////////////////////////
  ///           GETTERS
	/**
	 * @property {PaymentMethod} item The select payment method
   * @readonly
	 */
	get item() {
		return this._store().paymentMethod
	}

  /**
   * @property {Array<PaymentMethod>} methods The list of payment methods
   * @readonly
   */
  get list() {
    return this._store().paymentMethods
  }

	/**
	 * @property {Object}
   * @readonly
	 */
	get trigger() {
		return this._store().paymentTrigger
	}

  /**
	 * @property {String}
   * @readonly
	 */
  get redirect() {
    return this._store().paymentRedirect
  }
  
  /////////////////////////////////////////
  ///           METHODS
  /**
   * Apply the payment methods to the order
   * 
   * @async 
   * @param {Object} options
   * @param {Order} options.order
   * 
   * @returns {Object}
   */
  async apply({ order }) {
    try {
      if (basil.isNil(order)){
        order = this._parent.order
      }

      let { data } = await this._api.post({ 
        type: 'entity', 
        payload: { 
          order_id: order.id, 
          redirect: this.redirect, 
          item: { payment_method: this.item.slug }
        }
      })
      
      this._store().paymentTrigger = data
      return this.trigger
    } catch(e) {
      throw e
    }
  }
  
  /**
   * Fetch the list of payment methods for the current order
   *
   * @async
   * @param {object} options
   * @param {Order} options.order
   * 
   * @returns {Array<PaymentMethod>}
   */
  async find({ order }) {
    try {
      this._logger.info('checkout.payment.find')

      if (basil.isNil(order)){
        order = this._parent.order
      }

      if (basil.isNil(order)){
        throw Error.ORDER_NOTFOUND
      }
      
      let { data } = await this._api.get({ type: 'collection', payload: { order_id: order.id }})
      this._store().paymentMethods = data.map(m => new PaymentMethod(m))

      return this.list
    } catch(e) {
      throw e
    }
  }
  
  /**
	 * Search for payment method with the given slug
	 *
	 * @param {String} slug The slug to search for
   * 
   * @return {PaymentMethod}
	 */
	getMethodForSlug(slug){
		return this.list.find(e => e.slug === slug)
	}

  /**
   * Reset the data of the payment method selected
   */
  reset() { 
    this._store().paymentMethod = null
    this._store().paymentRedirect = null
  }

  /**
   * Set the current payment methods for the current order
   *
   * @async
   * @param {Object} options
   * @param {Order} options.order
   * @param {String} options.method
   * @param {String} options.redirect
   */
  async setMethod({ order, method, redirect = null }) {
    try {
      this._logger.info('checkout.payment.setPayment')
      this._logger.debug('-method', method)
      this._logger.debug('-redirect', redirect)

      if(basil.isNil(order)) {
        order = this._parent.order
      }

      if(basil.isNil(order)) {
        throw Error.ORDER_NOTFOUND
			}

			// if(basil.isNil(method) || basil.isNil(redirect)) {
			// 	throw Error.ORDER_PAYMENT_NOTVALID
			// }

			this._store().paymentMethod = method
      this._store().paymentRedirect = redirect
    } catch(e) {
      throw e
    }
  }
}
