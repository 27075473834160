export default {
  fqn: 'ifm.curry.local-storage',
  items: {
    cart: {
      scopes: ['embed']
    },
    'cart-service': {
      scopes: ['embed']
    },
    embed: {
      scopes: ['embed']
    },
    lang: {
      scopes: []
    },
    locale: {
      scopes: []
    },
    service: {
      scopes: ['embed']
    },
    theme: {
      scopes: ['embed']
    },
    user: {
      scopes: ['embed']
    }
  }
}
