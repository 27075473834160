export default {
  activate_sayl_wallet: { source: 'activate_sayl_wallet', type: Boolean, },
  active: { source: 'active', type: Boolean, },
  ageDisclaimer: { source: 'age_disclaimer', type: Boolean, },
  ageDisclaimerText: { source: 'age_disclaimer_text', type: String, },
  excludedServices: { source: 'id', type: Array, },
  created: { 
    source: 'created', 
    type: Object, 
    format: (value) => value && value.date ? Date.parse(value.date) : null
  },
  customCss: { source: 'custom_css', type: String },
  excludedServices: { source: 'excluded_services', type: String, },
  id: { source: 'id', type: String, },
  placeholders: { source: 'placeholders', type: Array, },
  shopId: { source: 'shop_id', type: String, },

  name: { source: 'name', type: String, },

  // Images
  logo: { 
    source: 'images', 
    type: String, 
    format: (value) => {
      if(!value || value == null || value.length === 0) {
        return null
      }

      let ret = value.find(i => i.field_name === 'logo')
      if(ret) {
        ret = ret.url
      }
      return ret ? ret : null
    }
  },
  banners: {
    source: 'images', 
    type: Array, 
    format: (value) => {
      if(!value || value == null || value.length === 0) {
        return null
      }

      let ret = value.filter(i => i.field_name === 'banner')
      if(ret && ret.length > 0) {
        ret = ret.map(i => i.url)
      }
      return ret ? ret : null
    }
  },

  // Legal
  copyright: { source: 'copyright', type: String, },
  generalConditions: { source: 'general_conditions', type: String, },
  privacyPolicy: { source: 'privacy_policy', type: String, },

  // Pages
  facebookPage: { source: 'facebook_page', type: String, },
  instagramPage: { source: 'instagram_page', type: String, },
  messengerPage: { source: 'messenger_page', type: String, },
  pinterestPage: { source: 'pinterest_page', type: String, },
  twitterPage: { source: 'twitter_page', type: String, },
  youtubePage: { source: 'youtube_page', type: String, },

  // Analytics
  gtagId: { source: 'gtag_id', type: String },
  gaId: { source: 'ga_id', type: String, },
  fbPixelId: { source: 'fb_pixel_id', type: String },
}
