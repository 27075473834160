import { useBootstrapStore } from './modules/bootstrap'

let instance = null

class SaylStore {
  constructor() {
    if(instance) {
      return instance 
    }

    instance = this
    instance.register('bootstrap', useBootstrapStore)
  }

  register(name, store) {
    Object.defineProperty(this, name, {
      value: store,
      configurable: false,
    })
  }
}

instance = new SaylStore()

export default instance