import { CurryTranslationModel } from '@spices/curry'
import Web3VoucherGeneration from './generation/model'

export default {
  chain: {
    source: 'chain',
    type: String
  },
  created: {
    source: 'created',
    type: Date
  },
  customerId: {
    source: 'customer_id',
    type: String
  },
  from: {
    format: value => value != null ? new Date(value) : null,
    source: 'from'
  },
  generation: {
    source: 'generation',
    type: Web3VoucherGeneration
  },
  id: {
    source: 'id',
    type: String
  },
  last_generation_date: {
    format: value => value != null ? new Date(value) : null,
    source: 'last_generation_date'
  },
  modified: {
    source: 'modified',
    type: Date
  },
  name: {
    source: 'name',
    type: CurryTranslationModel
  },
  nftId: {
    source: 'nft_id',
    type: String
  },
  projectId: {
    source: 'project_id',
    type: String
  },
  reference: {
    source: 'reference',
    type: String
  },
  slug: {
    source: 'slug',
    type: String
  },
  smartcontractAaddress: {
    source: 'smartcontract_address',
    type: String
  },
  status: {
    source: 'status',
    type: String
  },
  to: {
    format: value => value != null ? new Date(value) : null,
    source: 'to'
  },
  tokenHashes: {
    source: 'token_hashes',
    type: Array
  },
  tokenIds: {
    source: 'token_ids',
    type: Array
  },
  type: {
    source: 'type',
    type: String
  }
}