<template>
  <header
    class="n-header"
    v-if="enabled">
    <div
      class="n-header__container container"
      v-click-outside="outside">
      <!-- Second -->
      <div class="n-header__group n-header__second">
        <router-link :to="logoHref">
          <img
            alt="Sayl Conn3ct logo"
            class="n-header__logo"
            :src="logo"
          />
        </router-link>
      </div>

      <!-- Third -->
      <div class="n-header__group n-header__third">
        <!-- Locale -->
        <actions-button
          :appearance="$pepper.Appearance.DEFAULT"
          class="n-header__button n-header__item-locale locale"
          icon-post="angle-down"
          ref="locale"
          :size="$pepper.Size.M"
          @click="showLocales = !showLocales"
          v-if="hasMultipleLanguages">
          <span class="locale__label">{{ userLang }}</span>
        </actions-button>

        <actions-button
          class="n-header__theme"
          :key="`theme_${bootstrap.theme}`"
          :size="$pepper.Size.M"
          @click="switchTheme"
          v-if="bootstrap.themeable">
          <dark-icon v-if="bootstrap.isLightTheme" />
          <light-icon v-else />
        </actions-button>
      </div>
    </div>

    <popins-list-dropdown
      class="locales-dropdown"
      :layout="['field', 'label']"
      :options="langs"
      :target="$refs.locale"
      :visible="showLocales"
      @change="onChangeLang"
    ></popins-list-dropdown>
  </header>
</template>

<script>
import SaylStore from '@/bootstrap/stores'

import { ClickOutside } from '@spices/pepper'

import Config from '@/config'
import LightIcon from '@/icons/sun'
import DarkIcon from '@/icons/moon'

export default {
  name: 'NavigationsHeader',

  components: {
    LightIcon,
    DarkIcon
  },

  directives: {
    ClickOutside
  },

  inject: [
    '$core',
    '$embed',
    '$localStorage',
    '$user',
  ],

  data() {
    return {
      bootstrap: SaylStore.bootstrap(),
      outside: {
        handler: this.onClickOutside,
        middleware: this.clickOutsideMiddleware
      },

      showLocales: false,
      key: 1,
    }
  },

  computed: {
    embed() {
      return this.$localStorage.embed || this.$route.params.embed
    },

    enabled() {
      let meta = this.$route.meta
      return !meta.hasOwnProperty('header') || (meta.hasOwnProperty('header') && meta.header === true)
    },

    hasMultipleLanguages() {
      return this.$basil.get(this.langs, 'length', 0) > 1
    },

    i18n() {
      return this.bootstrap.i18n
    },

    langs() {
      return this.locales.map((l) => {
        return {
          label: this.$t(`sayl-front.lang_${l.iso.toLowerCase()}`),
          value: l,
          active: l.lang === this.userLang
        }
      })
    },

    locales() {
      return this.bootstrap.i18n.locales
    },

    logo() {
      return this.$basil.get(this.shop, 'logo', `${ Config.cdn }statics/images/logos/connect/color.svg`)
    },

    logoHref() {
      let ret = { name: 'sayl-front-catalog.catalog' }
      if(this.$route.params.embed) {
        ret = { name: 'sayl-front-catalog.catalog', params: {embed: this.$route.params.embed}}
      }
      return ret
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    userLang() {
      return this.$basil.get(this.user, 'lang', this.i18n.locale.lang.toString())
    },
  },

  methods:{
    clickOutsideMiddleware() {
      return true
    },

    async onChangeLang(langs) {
      try {
        const { value } = langs.find(l => l.active)
        this.showLocales = false
        this.i18n.setLocale(value)
  
        await this.$core.changeLang({ locale: value.iso })
        this.$bus.$emit('change-lang')
      } catch(e) {
        $console.error(e)
      }
    },

    onClickOutside() {
      this.showFavorites = false
      this.showRecent = false
      this.showUser = false
    },

    switchTheme() {
      this.bootstrap.switchTheme()
    }
  },
}
</script>
