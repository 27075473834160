import NotFound from './views/errors/notfound'

const routes = [
  {
    path: '*',
    component: NotFound,
    meta: {
      bodyClass: 'layout-base',
      scrollTop: true,
      header: true,
      footer: true
    }
  }
]

export default routes
