export default {
  fqn: 'ifm.checkout.voucher',

  endpoints: {
    entity: {
      post: '/checkout/$order_id/voucher',
      delete: '/checkout/$order_id/voucher'
    },

    transport: 'cp'
  }
}
