import ProductManifest from '../manifest'
import Web3Voucher from './web3-vouchers/model'

export default {
  ...ProductManifest,

  availableSupply: { source: 'available_supply', format: (value) => value == null ? null : Number(value)},
  lockedSupply: { source: 'locked_supply', format: (value) => value == null ? null : Number(value)},
  imageType: { source: 'image_type', type: String, },
  nft: { source: 'nft', type: Object },
  nftId: { source: 'nft_id', type: String },
  nftImage: { source: 'nft_image', type: String },
  nftAttributes: { source: 'nft_attributes', type: String },
  nftMintingFromPool: { source: 'nft_minting_from_pool', type: Boolean },
  promoPrice: { source: 'promo_price', default: null, type: Number, },
  tags: { source: 'tags', type: Array },
  vatClass: { source: 'vat_class', type: String },
  unitPrice: { source: 'unit_price', default: null, type: Number, },
  web3Vouchers: { source: 'web3_vouchers', type: Array, format: (values) => values ? values.map(v => new Web3Voucher(v)) : [] },
}