import { CurryModel } from '@spices/curry'
import ProductManifest from './manifest'

/** 
 * @class
 * @author Valentin Gregoire <valentin@infinity-mobile.io>
 */
export default class Product extends CurryModel {
  /**
   * @constructor
   */
  constructor(data = null, manifest = null) {
    super({ data, manifest: manifest == null ? ProductManifest : manifest })
  }
}
