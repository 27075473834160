<template>
  <div :class="classes">
    <!-- Header -->
    <header 
      class="ui-error__header"
      v-if="header">
      <img 
        alt="Sayl Connect Logo"
        :src="logo" 
      />
    </header>

    <!-- Body -->
    <div class="ui-error__body">
      <h1 class="ui-error__title">{{ title }}</h1>

      <h2 
        class="ui-error__subtitle"
        v-if="subtitle" 
      >{{ subtitle }}</h2>
    </div>

    <!-- Footer -->
    <footer class="ui-error__footer">
      <actions-button 
        appearance="primary" 
        :size="actionSize"
        @click="$emit('action')"
        v-if="actionLabel"
      >{{ actionLabel }}</actions-button>

      <p class="ui-error__description" v-html="description"></p>
    </footer>
  </div>
</template>


<script>
import Config from '@/config'

export default {
  name: 'UiError',

  props: {
    actionLabel: {
      type: String
    },
    actionSize: {
      default: 'm',
      type: String
    },
    appearance: {
      default: 'primary',
      type: String
    },
    description: {
      type: String
    },
    header: {
      default: true,
      type: Boolean
    },
    subtitle: {
      type: String
    },
    title: {
      required: true,
      type: String
    }
  },

  computed: {
    classes() {
      return {
        'ui-error': true,
        '-primary': this.appearance === 'primary',
        '-secondary': this.appearance === 'secondary'
      }
    },

    logo() {
      return Config.cdn + 'statics/images/logos/connect/color.svg'
    },
  }
}
</script>
