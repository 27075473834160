import { CurryApi } from '@spices/curry'

import Config from './config'
import NftCatalog from '../../nfts/models/nfts/catalogs/model'

/**
 * @class
 */
export default class ProductController {
  /**
   * @constructor
   * 
   * @param {Object} options
   * @param {Object} options.logger - Logger we use in all the app. In the view $console and here logger. See @spices/cayenne
   * @param {Object} options.store - Store for the Catalog management
   * @param {Object} options.transports - Object containing cp and HTTP. See @/bootstrap/data/api/transports
   */
  constructor({ logger, store, transports }) {
    this._api = new CurryApi({ config: Config, transports })
    this._logger = logger
    this._store = store
  }

  /////////////////////////////////////////
  ///           GETTERS
  /**
   * @property {Array<NftCatalog>}
   * @readonly
   */
  get products() {
    return this._store().products
  }

  /**
   * @property {NftCatalog}
   * @readonly
   */
  get product() {
    return this._store().product
  }

  /////////////////////////////////////////
  ///           INIT
  /**
   * Initialize the list of products
   * 
   * @async
   * @param {Object} options 
   * @param {Array} options.products
   * 
   * @returns {Array<NftCatalog>}
   */
  async init({ products }) {
    try {
      this._logger.info('catalog.product.init')

      // 1. Sort product placing bundle type at the end
      // 2. Format the product based on the type
      // 3. Only the visible products - TODO: Reactivate this after
      this._store().products = products
        .map((product) => this._formatByType(product))
        .filter(p => p.type === 'nft')
        .filter((p) => !p.hidden)

      return this.products
    } catch(e) {
      throw e
    }
  }

  /////////////////////////////////////////
  ///           METHODS
  /**
   * Format the product with the right Model base on the type of product
   *  eg: default or bundle
   * 
   * @private
   * @param {Object} product 
   * @param {Array<Product>} products 
   */
  _formatByType(product) {
    let ret = null

    switch(product.type) {
      case 'nft':
        ret = new NftCatalog(product)
        break
    }

    return ret
  }

  /**
   * Find a product based on it's id
   * 
   * @async
   * @param {Object} options 
   * @param {String} options.id 
   * 
   * @returns {NftCatalog}
   */
  async findById({ id }) {
    try {
      this._logger.info('catalog.product.view')

      let { data } = await this._api.get({ type: 'entity', payload: { id }})
      this._store().product = this._formatByType(data)
      return this.product
    } catch(e) {
      throw e
    }
  }

  /**
   * Find a product matching the given id in the list of products
   * 
   * @param {String} id 
   * 
   * @returns {NftCatalog}
   */
  getProductById(id){
    this._logger.info('catalog.product.getProductById')
    return this.products.find(p => p.id === id)
  }
}
